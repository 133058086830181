import React from 'react'
import { Button, Container, Row } from 'react-bootstrap'
import classimg from './robowhizimages/Group 1 (8).png'
import mobile from './robowhizimages/mobile.svg'
function Rhoboclass() {
  // Handle the click event of the "Book Free Class" button
  const handleBookFreeClassClick = () => {
    const speakToTeamSection = document.getElementById("speakToTeamSection");
    if (speakToTeamSection) {
      speakToTeamSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Container fluid className='roboclasswrapper'>
        <Row className='rhoboclassrow'>
          <img src={mobile} alt="" className='mobile'/>
            <span>Checkout what a <br /><span>Robo Whiz class looks like</span></span>
            <br />
            <div 
            style={{padding:'0',margin:"4rem 0",maxWidth:'944px'}}
            
            >

            <iframe
            width="100%"
            height="470"
            src="https://www.youtube.com/embed/1gYyhr5eE_c"
            title="YouTube video player"//
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{borderRadius:'12px',padding:'0'}}
          />
                        
                        </div>
            <br />
            <Button className="bannerbutton" onClick={handleBookFreeClassClick}>Book Your Free Demo</Button>

        </Row>
    </Container>
  )
}

export default Rhoboclass