import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import image1 from "./csimages/Group 810 (1).png";
import "./Codingschool.css";
function CsAbout() {
  return (
    <>
      <Container fluid className="csawrapper">
        <Row className="csrow">
          <Col className="csacol2">
            <img src={image1} alt="" />
          </Col>
          <Col className="csacol1">
            <span className="csaheading">
              <span className="csaheadsubspan">Coding School</span>
            </span>
            <br />
            <br />
            <span className="csacontent">
              Coding School (CS) is a division of Airtics Education dedicated to
              unlocking the full potential of children in the fields of AI,
              robotics, game development, web designing, python designing, and
              coding skills. It promotes a science and technology culture and
              encourages lifelong learning while maintaining efficiency,
              effectiveness, and top-notch education.Coding School’s creativity
              STEAM enhancement package aims to engage children and young adults
              (from age 6 to 16) with supplemental skills in Science,
              Technology, Engineering, and Mathematics (STEM), thus giving them
              the skills and motivation needed to create a better future.
            </span>
            <br />
            <br />
            <span className="csacontent">
              Our programs are designed to prioritise fun, active engagement,
              and an integrated, multi-disciplinary approach to problem-solving
              and creativity. We focus on activities that encourage creativity
              and innovation, emphasising STEM application strongly.In addition
              to coding, the children can develop high-tech electrical,
              mechanical, and computer engineering skills through the
              construction and operation of robots, and delve into the world of
              Artificial Intelligence, Augmented Reality, Virtual Reality and
              Cyber Security.
            </span>
          </Col>
        </Row>
      </Container>
      <Container fluid className="stemwrapper">
        <Row className="stemrow">
          <Row className="stemrowcontent">
            <span className="title">
              <span className="bold">STEM</span>.org accredited
            </span>
            <br />
            <span className="content">
              As a recognition of the quality and efficacy of our Robotics and
              Coding STEM-based programmes, Coding School received accreditation
              from STEM.org – the longest continually operating STEM education
              research and credentialing organization in the United States. The
              accreditation further validates our educational approach to
              teaching students coding and computer science with a unique
              STEM-based curriculum.
            </span>
          </Row>
        </Row>
      </Container>
    </>
  );
}

export default CsAbout;
