import React from 'react'
import './ContactUs.css'
import Banner from './Banner'
import Connect from './Connect'

function ContactUs() {
  return (
    <>
    <Banner />
    <Connect />
    </>
  )
}

export default ContactUs