import React, { useEffect } from "react";
import Banner from "./Banner";
import Learn from "./Learn";
import Innovator from "./Innovator";
import SpeakToTeam from "../Homescreen/SpeakToTeam";
import Testimonials from "../Robowhiz/Testimonials";
import LearningPrograms from "../Homescreen/LearningPrograms";
import Questions from "../Robowhiz/Questions";
import Global from "../Robowhiz/Global";
import Rhoboclass from "../Robowhiz/Rhoboclass";
import { Col, Container, Row } from "react-bootstrap";
import p1 from "../Robowhiz/robowhizimages/Group 570.svg";
import p2 from "../Robowhiz/robowhizimages/Group 576.svg";
import p3 from "../Robowhiz/robowhizimages/Group 578.svg";
import p4 from "../Robowhiz/robowhizimages/Group 579.svg";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
// import Innovator from './Innovator'

function OurPrograms({ champ, gaming, metaverse }) {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top whenever the URL changes
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      {gaming && (
        <>
          <Helmet>
            <title>Game Development for Kids in UAE | Coding School</title>
            <meta
              name="description"
              content="Game development can be a creative and educational endeavor for kids in the UAE. It allows children to learn problem-solving, coding, and design skills while having fun"
            />
            <meta
              property="og:title"
              content="Game Development for Kids in UAE | Coding School"
            />
            <meta
              property="og:description"
              content="Game development can be a creative and educational endeavor for kids in the UAE. It allows children to learn problem-solving, coding, and design skills while having fun"
            />
          </Helmet>
        </>
      )}

      {champ && (
        <>
          <Helmet>
            <title>
              Child Skill Developement in UAE- Champ Coder | Coding School
            </title>
            <meta
              name="description"
              content="Child skill development is a crucial aspect of a child's growth and education. By fostering a wide range of skills in children, parents, teachers, and caregivers can help them become well-rounded individuals"
            />
            <meta
              property="og:title"
              content="Child Skill Developement in UAE- Champ Coder | Coding School"
            />
            <meta
              property="og:description"
              content="Child skill development is a crucial aspect of a child's growth and education. By fostering a wide range of skills in children, parents, teachers, and caregivers can help them become well-rounded individuals"
            />
          </Helmet>
        </>
      )}
      {metaverse && (
        <>
          <Helmet>
            <title>
              Artificial Intelligence Programs for Kids in UAE | Coding School
            </title>
            <meta
              name="description"
              content="Introducing kids to artificial intelligence (AI) can be a great way to foster their interest in technology and computer science. There are various AI programs and resources suitable for children in UAE"
            />
            <meta
              property="og:title"
              content="Artificial Intelligence Programs for Kids in UAE | Coding School"
            />
            <meta
              property="og:description"
              content="Introducing kids to artificial intelligence (AI) can be a great way to foster their interest in technology and computer science. There are various AI programs and resources suitable for children in UAE"
            />
          </Helmet>
        </>
      )}
      <Banner />
      <Learn />
      <Innovator champ={champ} gaming={gaming} metaverse={metaverse} />
      {(gaming || champ || metaverse) && (
        <>
          <Container className="posterwrapper" fluid>
            <Row className="postorsrow">
              <Col className="postorscol">
                <div className="content">
                  <img
                    src={p1}
                    alt=""
                    style={{
                      width: "74.366px",
                      height: "61.934px",
                      margin: "0 auto 0",
                    }}
                  />
                  <span className="heading">1M+</span>
                  <br />
                  <span className="description">Future Leaders</span>
                </div>
                <div className="stick"></div>
              </Col>
              <Col className="postorscol">
                <div className="content">
                  <img
                    src={p2}
                    alt=""
                    style={{
                      width: "62.893px",
                      height: "55px",
                      margin: "0 auto 20px",
                    }}
                  />

                  <span className="heading">500k+</span>
                  <br />
                  <span className="description">Monthly Class</span>
                </div>
                <div className="stick"></div>
              </Col>
              <Col className="postorscol">
                <div className="content">
                  <img
                    src={p3}
                    alt=""
                    style={{
                      width: "48.684px",
                      height: "48.684px",
                      margin: "0 auto 20px",
                    }}
                  />

                  <span className="heading">30+</span>
                  <br />
                  <span className="description">Countries</span>
                </div>
                <div className="stick"></div>
              </Col>
              <Col className="postorscol">
                <div className="content">
                  <img
                    src={p4}
                    alt=""
                    style={{
                      width: "47.822px",
                      height: "45.239px",
                      margin: "0 auto 20px",
                    }}
                  />

                  <span className="heading">4.8/5</span>
                  <br />
                  <span className="description">Global Rating</span>
                </div>
                <div
                  className="stick"
                  style={{ visibility: "hidden" }}
                  id="sticklast"
                ></div>
              </Col>
            </Row>
          </Container>
          <Rhoboclass />
          <Global />
          <Testimonials />
          <Questions />
          <LearningPrograms Robowhiz="true" />
        </>
      )}
      <SpeakToTeam />
    </>
  );
}

export default OurPrograms;
