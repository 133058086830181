import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import topimg from "./csimages/Group 795.svg";
import btmimg from "./csimages/Group 802.svg";
import image1 from "../Homescreen/homeimages/Group 592.svg";
import image2 from "../Homescreen/homeimages/Group 623 (1).svg";

function Whycs() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobile = screenWidth < 480;

  useEffect(() => {
    // Function to update screen width in state
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add an event listener to update screen width on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Container fluid className="whycswrapper">
        <Row className="whycstitlerow">
          <span>
            Why <span>Coding School?</span>
          </span>
        </Row>
        <Row className="whycscardrow">
          <Col className="whycscards">
            <img src={topimg} alt="" className="topimg" />
            <span className="cstitle">
              One-of-a-kind <br /> platform
            </span>
            <br />
            <span className="cscontent">
              We provide a comprehensive technology curriculum for children that
              ranges from the basics of coding to scientific inquiry and from
              design thinking to robotics.
            </span>
          </Col>
          <Col className="whycscards">
            <span className="cstitle">
              Helps to excel in <br /> academics
            </span>
            <br />
            <span className="cscontent">
              With the help of our one-of-a-kind scientific exploration program,
              children will thoroughly comprehend many math and science
              concepts.
            </span>
          </Col>
          <Col className="whycscards">
            <span className="cstitle">
              Hands-on project- <br /> based learning
            </span>
            <br />
            <span className="cscontent">
              Children will create hardware, scientific simulations, and
              real-world smartphone apps like tech entrepreneurs and scientists.
            </span>
          </Col>
          <Col className="whycscards">
            <img src={btmimg} alt="" className="btmimg" />
            <span className="cstitle">
              Develop new age <br /> skills
            </span>
            <br />
            <span className="cscontent">
              Children will improve their abilities to solve complicated
              problems, think critically, be creative, have emotional
              intelligence, and be cognitively flexible.
            </span>
          </Col>
        </Row>
      </Container>
      <Container fluid className="csfuturewrapper">
        <Row className="csfuturerow1">
          {isMobile ? (
            <img src={image2} alt="" className="bgimage" style={{transform:'translate(1%,7%)'}}/>
          ) : (
            <img src={image1} alt="" className="bgimage" />
          )}
          <span>
            How is Coding School <span>‘Coding the Future’?</span>
          </span>
        </Row>
        <Row className="csfuturerow2">
          <Col className="fcol1">
            <span>
              At Coding School, students learn core coding skills and the
              fundamentals of robotics, AI, AR and VR through our project-based
              lessons and unique curriculum-based coding environment.Instead of
              concentrating on just finding the answer or finishing quickly, we
              encourage students to try new things, take their time, and take
              chances. We accomplish this through a dynamic curriculum.
              <br />
              <br />
              Moreover, the project-based learning environment encourages our
              students to take risks and accept making errors, which, according
              to research, builds important 21st-century life skills like:
            </span>
          </Col>
          <Col className="fcol2">
            <ul>
              <li>Personal and social responsibility</li>
              <li>Planning, critical thinking, reasoning, and creativity</li>
              <li>
                Strong communication skills, both for interpersonal and
                presentation needs
              </li>
              <li>Cross-cultural understanding</li>
              <li>Visualising and decision making</li>
              <li>Knowing how and when to use technology</li>
              <li>Choosing the most appropriate tool for the task</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Whycs;
