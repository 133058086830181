import React, { useEffect, useState } from "react";
import arrow from "./robowhizimages/Group 1 (29).svg";
import { styled } from "styled-components";

function RightArrow({ onClick, companies, testimonials, global }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobile = screenWidth < 870;
  const isMobile480 = screenWidth < 480;

  useEffect(() => {
    // Function to update screen width in state
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add an event listener to update screen width on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Div
      companies={companies}
      style={{
        left:
          testimonials && !isMobile
            ? "112px"
            : isMobile && testimonials
            ? "50%"
            : isMobile480 && global
            ? "60%"
            : "",
        bottom: testimonials ? "-36px" : "",
      }}
      testimonials={testimonials}
    >
      <Div1 onClick={onClick}>
        <Image src={arrow} alt="" />
      </Div1>
    </Div>
  );
}
// bottom: -36px;
// left: 34px;
export default RightArrow;

const Div = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  bottom: ${(props) => (props.companies ? "-61%" : "-21%")};
  /* right:2%; */
  right: 6%;
  opacity: 1;
  @media (max-width: 480px) {
    right: 8%;
    top: ${(props) => (props.testimonials ? "95%" : "104%")};
  }
  @media (max-width: 375px) {
    right: 25%;
    top: ${(props) => (props.testimonials ? "" : "104%")};
  }
`;

const Div1 = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  opacity: 1;
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
  transform: translate(-50%, -50%);
`;
