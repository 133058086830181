import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function Thrive() {
  return (
    <Container fluid className="thrivewrapper">
      <Row className="thrivetitlerow">
        <span>
          How does Coding School <span>help your child thrive?</span>
        </span>
      </Row>
      <Row className="thrivecontent">
        <Col className="thrivecol">
          <span className="tcontenttitle">Interactive visual learning</span>
          <br />
          <span className="tcontent">
            Your child learns by actively participating in interactive, visual,
            online activities and classes rather than by passively listening to
            a teacher lecture. This all-encompassing method aids your child's
            natural learning of the subject matter while aiding in improved
            long-term memory.
          </span>
        </Col>
        <Col className="thrivecol">
          <span className="tcontenttitle">Individualised learning method</span>
          <br />
          <span className="tcontent">
            Your child's teacher and particular online coursework will be
            selected based on their learning level and offer as much
            personalisation as feasible.
          </span>
        </Col>
        <Col className="thrivecol">
          <span className="tcontenttitle">
            Smart education through technology
          </span>
          <br />
          <span className="tcontent">
            We have established a sophisticated technology foundation that is
            secure, dependable, and kid-friendly. Our hands-on, future-focused
            approach to education assists your child in developing their
            critical and open-minded thinking skills for both the present and
            the future.
          </span>
        </Col>
      </Row>
    </Container>
  );
}

export default Thrive;
