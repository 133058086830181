import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import image1 from "./homeimages/Group 617.png";
import arrow from "./homeimages/tabler_chevron-down.svg";
import { useInView } from "react-intersection-observer";

function Provide() {
  const [btn1, setBtn1] = useState(true);
  const [btn2, setBtn2] = useState(false);
  const [btn3, setBtn3] = useState(false);
  const [btn4, setBtn4] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [animated, setAnimated] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: false, // Trigger animation each time the element comes into view
    threshold: 0.1, // Adjust this threshold as needed
  });

  // Use useEffect to update animated state when inView changes
  React.useEffect(() => {
    if (inView) {
      console.log("inview");
      setAnimated(true);
    }
  }, [inView]);
  const isMobile = screenWidth < 480;

  useEffect(() => {
    // Function to update screen width in state
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add an event listener to update screen width on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const ButtonOneHandler = () => {
    setBtn1(true);
    setBtn2(false);
    setBtn3(false);
    setBtn4(false);
  };
  const ButtonTwoHandler = () => {
    setBtn1(false);
    setBtn2(true);
    setBtn3(false);
    setBtn4(false);
  };
  const ButtonThreeHandler = () => {
    setBtn1(false);
    setBtn2(false);
    setBtn3(true);
    setBtn4(false);
  };
  const ButtonFourHandler = () => {
    setBtn1(false);
    setBtn2(false);
    setBtn3(false);
    setBtn4(true);
  };
  return (
    <Container fluid className="providewrapper">
      <Row className="providerow">
        <span
        ref={ref}
        className={`provideheading ${
          animated ? "animate__animated animate__zoomIn" : ""
        }`}
        >
          What we <span className="dheadsubspan">Provide</span>
        </span>
      </Row>
      <Row className="providerow2">
        <Button
          className="pbutton"
          style={{
            background: btn1 ? "#fff" : "#e5e2ec",
            borderRadius: "0",
          }}
          onClick={ButtonOneHandler}
        >
          <span>Robotics Lab Set Up in Schools</span>
          <img
            src={arrow}
            alt=""
            className="arrow"
            style={{ transform: btn1 ? "" : "rotate(90deg)" }}
          />
          <div className="line" style={{ display: btn1 ? "none" : "" }} />
        </Button>
        {isMobile && (
          <>
            {btn1 && (
              <>
                <Row className="providerow3">
                  <Col className="providercol1">
                    <img src={image1} alt="" />
                  </Col>
                  <Col className="providercol2">
                    <span className="providecontent">
                      Coding School is your one-stop solution for robotics and
                      creative labs in schools, equipped with state-of-the-art
                      equipment, kits, and accessories. Our platforms, including
                      Lego, Tetris, Arduino, and Raspberry Pi, provide a
                      hands-on approach to building robots and stimulate student
                      excellence in science following the IGCSE, CG, CBSE, and
                      ICSE board syllabuses. <br /> Our STEM lab offers a unique
                      replacement or supplement to traditional science teaching
                      methods. Say goodbye to chalk and board or digital content
                      worksheets, and embrace a new way of learning. Developed
                      by VEX Robotics, our supplemental educational resource
                      includes STEM lesson plans aligned with the highest
                      scholarly standards. Each lab features hands-on lessons
                      that promote the practical application and learning across
                      all four disciplines of STEM, while fostering teamwork and
                      collaboration among students. <br /> With our STEM lab,
                      students actively participate in practical and in-depth
                      learning processes, allowing them to retain information
                      more effectively than in a traditional classroom setting.
                      Prepare your students for the future by providing them
                      with an engaging and dynamic learning experience in our
                      cutting-edge STEM lab at Coding School.
                    </span>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
        <Button
          className="pbutton"
          style={{
            background: btn2 ? "#fff" : "#e5e2ec",
            borderRadius: "0",
          }}
          onClick={ButtonTwoHandler}
        >
          <span>Robotic Exhibitions</span>
          <img
            src={arrow}
            alt=""
            className="arrow"
            style={{ transform: btn2 ? "" : "rotate(90deg)" }}
          />

          <div className="line" style={{ display: btn2 ? "none" : "" }} />
        </Button>
        {isMobile && (
          <>
            {btn2 && (
              <>
                <Row className="providerow3">
                  <Col className="providercol1">
                    <img src={image1} alt="" />
                  </Col>
                  <Col className="providercol2">
                    <span className="providecontent">
                      At Coding School, we believe that the Robotics Revolution
                      is a significant technological shift that will shape the
                      course of history and impact the lives of present and
                      future generations. To give students a glimpse into this
                      exciting future, we offer engaging and informative robotic
                      exhibitions.
                      <br /> Our robotic exhibitions cover a wide range of
                      topics, including Humanoid robots, Artificial Intelligence
                      and Machine Learning, Electronics, Coding, Industrial
                      Automation, and Drones. Through these exhibitions,
                      students will have the opportunity to explore and interact
                      with cutting-edge technologies, gaining insights into the
                      world of robotics and its potential impact on society.
                      <br />
                      If hosting an exhibition at your school is not possible,
                      don't worry! We also offer tours of our advanced robotics
                      lab, where students can witness firsthand the latest
                      innovations in robotics and experience hands-on learning
                      opportunities.
                      <br />
                      Join us in embracing the future of robotics through our
                      captivating robotic exhibitions and inspire your students
                      to be part of the Robotics Revolution.
                    </span>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
        <Button
          className="pbutton"
          style={{
            background: btn3 ? "#fff" : "#e5e2ec",
            borderRadius: "0",
          }}
          onClick={ButtonThreeHandler}
        >
          <span>Futuristic Workshops</span>
          <img
            src={arrow}
            alt=""
            className="arrow"
            style={{ transform: btn3 ? "" : "rotate(90deg)" }}
          />

          <div className="line" style={{ display: btn3 ? "none" : "" }} />
        </Button>
        {isMobile && (
          <>
            {btn3 && (
              <>
                <Row className="providerow3">
                  <Col className="providercol1">
                    <img src={image1} alt="" />
                  </Col>
                  <Col className="providercol2">
                    <span className="providecontent">
                      Our exciting workshops offer a fun and educational
                      experience for kids and teens. Starting from Grade 1 and
                      progressing through Grade 12, our Coding School Workshop
                      focuses on Robotics, Artificial Intelligence, Electronics,
                      Machine Learning, 3D Printing, and the Internet of Things
                      (IoT).
                      <br />
                      Why is it important for your child to enrol in Robotics
                      and other related workshops? Investing in your child's
                      education in Robotics and related domains can provide them
                      with invaluable skills and knowledge that will benefit
                      them throughout their academic and professional journey.
                    </span>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
        <Button
          className="pbutton"
          style={{
            background: btn4 ? "#fff" : "#e5e2ec",
            borderRadius: "0",
          }}
          onClick={ButtonFourHandler}
        >
          <span>Assistance in Robotics Projects</span>
          <img
            src={arrow}
            alt=""
            className="arrow"
            style={{ transform: btn4 ? "" : "rotate(90deg)" }}
          />
        </Button>
        {isMobile && (
          <>
            {btn4 && (
              <>
                <Row className="providerow3">
                  <Col className="providercol1">
                    <img src={image1} alt="" />
                  </Col>
                  <Col className="providercol2">
                    <span className="providecontent">
                      Robots are designed to accomplish tasks that humans may
                      find challenging or prefer not to do. From exploring Mars
                      to working tirelessly on a manufacturing line to providing
                      companionship, robots have a wide range of applications.
                      We are here to help your kid create a robotics project for
                      his/her school with our cutting-edge equipment and expert
                      guidance from our highly qualified trainers. <br />
                      Our trainers are industry professionals who lead research
                      projects and deliver courses, ensuring your project is in
                      capable hands. Let us assist you in bringing your robotics
                      project to life!
                    </span>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Row>
      {isMobile ? (
        ""
      ) : (
        <>
          {btn1 && (
            <>
              <Row className="providerow3">
                <Col className="providercol1">
                  <img src={image1} alt="" />
                </Col>
                <Col className="providercol2">
                  <span className="providecontent">
                    Coding School is your one-stop solution for robotics and
                    creative labs in schools, equipped with state-of-the-art
                    equipment, kits, and accessories. Our platforms, including
                    Lego, Tetris, Arduino, and Raspberry Pi, provide a hands-on
                    approach to building robots and stimulate student excellence
                    in science following the IGCSE, CG, CBSE, and ICSE board
                    syllabuses. <br /> Our STEM lab offers a unique replacement
                    or supplement to traditional science teaching methods. Say
                    goodbye to chalk and board or digital content worksheets,
                    and embrace a new way of learning. Developed by VEX
                    Robotics, our supplemental educational resource includes
                    STEM lesson plans aligned with the highest scholarly
                    standards. Each lab features hands-on lessons that promote
                    the practical application and learning across all four
                    disciplines of STEM, while fostering teamwork and
                    collaboration among students. <br /> With our STEM lab,
                    students actively participate in practical and in-depth
                    learning processes, allowing them to retain information more
                    effectively than in a traditional classroom setting. Prepare
                    your students for the future by providing them with an
                    engaging and dynamic learning experience in our cutting-edge
                    STEM lab at Coding School.
                  </span>
                </Col>
              </Row>
            </>
          )}
        </>
      )}

      {btn2 && !isMobile && (
        <>
          <Row className="providerow3">
            <Col className="providercol1">
              <img src={image1} alt="" />
            </Col>
            <Col className="providercol2">
              <span className="providecontent">
                At Coding School, we believe that the Robotics Revolution is a
                significant technological shift that will shape the course of
                history and impact the lives of present and future generations.
                <br />
                To give students a glimpse into this exciting future, we offer
                engaging and informative robotic exhibitions.
                <br /> Our robotic exhibitions cover a wide range of topics,
                including Humanoid robots, Artificial Intelligence and Machine
                Learning, Electronics, Coding, Industrial Automation, and
                Drones. Through these exhibitions, students will have the
                opportunity to explore and interact with cutting-edge
                technologies, gaining insights into the world of robotics and
                its potential impact on society.
                <br />
                If hosting an exhibition at your school is not possible, don't
                worry! We also offer tours of our advanced robotics lab, where
                students can witness firsthand the latest innovations in
                robotics and experience hands-on learning opportunities.
                <br />
                Join us in embracing the future of robotics through our
                captivating robotic exhibitions and inspire your students to be
                part of the Robotics Revolution.
              </span>
            </Col>
          </Row>
        </>
      )}
      {btn3 && !isMobile && (
        <>
          <Row className="providerow3">
            <Col className="providercol1">
              <img src={image1} alt="" />
            </Col>
            <Col className="providercol2">
              <span className="providecontent">
                Our exciting workshops offer a fun and educational experience
                for kids and teens. Starting from Grade 1 and progressing
                through Grade 12, our Coding School Workshop focuses on
                Robotics, Artificial Intelligence, Electronics, Machine
                Learning, 3D Printing, and the Internet of Things (IoT).
                <br />
                <br />
                Why is it important for your child to enrol in Robotics and
                other related workshops? Investing in your child's education in
                Robotics and related domains can provide them with invaluable
                skills and knowledge that will benefit them throughout their
                academic and professional journey.
              </span>
            </Col>
          </Row>
        </>
      )}
      {btn4 && !isMobile && (
        <>
          <Row className="providerow3">
            <Col className="providercol1">
              <img src={image1} alt="" />
            </Col>
            <Col className="providercol2">
              <span className="providecontent">
                Robots are designed to accomplish tasks that humans may find
                challenging or prefer not to do. From exploring Mars to working
                tirelessly on a manufacturing line to providing companionship,
                robots have a wide range of applications. We are here to help
                your kid create a robotics project for his/her school with our
                cutting-edge equipment and expert guidance from our highly
                qualified trainers. <br />
                Our trainers are industry professionals who lead research
                projects and deliver courses, ensuring your project is in
                capable hands. Let us assist you in bringing your robotics
                project to life!
              </span>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default Provide;
