import React, { useEffect } from 'react'
import './Robowhiz.css'
import Banner from '../Homescreen/Banner'
import WhyChoose from './WhyChoose'
import Programs from './Programs'
import SpeakToTeam from '../Homescreen/SpeakToTeam'
import LearningPrograms from '../Homescreen/LearningPrograms'
import Rhoboclass from './Rhoboclass'
import Questions from './Questions'
import Global from './Global'
import Testimonials from './Testimonials'
import { Helmet } from 'react-helmet'

function Robowhiz() {
  useEffect(() => {
    // Scroll to the top of the page when the component first loads
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
    <Helmet>
      <title>Robotics for Kids | How to make a Robot | Robotics Training Programs UAE | Coding School</title>
      <meta
      name="description"
      content="We provide robotics training programs for kids in UAE which will help the beginners to learn on how to make a robot with the support from expert tutors."
    />
    <meta property="og:title" content="Robotics for Kids | How to make a Robot | Robotics Training Programs UAE | Coding School" />
    <meta property="og:description" content="We provide robotics training programs for kids in UAE which will help the beginners to learn on how to make a robot with the support from expert tutors." />
    </Helmet>
    <Banner Robowhiz='true'/>
    <WhyChoose />
    <Programs />
    <Rhoboclass />
    <Global />
    <Testimonials />
    <Questions />
    <LearningPrograms Robowhiz='true'/>
    <SpeakToTeam />
    </>
  )
}

export default Robowhiz