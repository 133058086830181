import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
// import bannerimg from "./homeimages/bannerimg.svg";
import bannerimg from "./homeimages/Group 1 (18).png";
import bannerimganim from "./homeimages/Group 2.png";
import robomobbannerimg from "./homeimages/robomob.svg";
import bannertop from "./homeimages/Group 1 (27).svg";
import robowhiztop from "./homeimages/Group 560.svg";
import bannerbottom from "./homeimages/Group 590 (1).svg";
function Banner({ Robowhiz }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    const updatePosition = (e) => {
      const newX = e.clientX / 5; // Adjust the division factor to reduce movement
      const newY = e.clientY / 0; // Adjust the division factor to reduce movement
      setPosition({ x: newX, y: newY });
    };

    document.addEventListener('mousemove', updatePosition);

    return () => {
      document.removeEventListener('mousemove', updatePosition);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const divStyle = {
    position: 'absolute',
    left: `${position.x}px`,
    top: `${position.y}px`,
    transition: 'transform 0.2s ease-in-out',
    transform: 'translate(-50%, -50%)',
  };
  const isMobile = screenWidth < 480;
  const is395 = screenWidth < 420;

  // Handle the click event of the "Book Free Class" button
  const handleBookFreeClassClick = () => {
    const speakToTeamSection = document.getElementById("speakToTeamSection");
    if (speakToTeamSection) {
      speakToTeamSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // Function to update screen width in state
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add an event listener to update screen width on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Container fluid className="banner">
      {/* <div>
        <img src={bannerbkd} alt="" />
      </div> */}
      <Row className="bannerrowwrapper">
        <img className="bannertop" src={Robowhiz ? robowhiztop : bannertop} alt="" style={{transform: Robowhiz ? 'translate(0%, 40px)' : ''}}/>
        <img className="bannerbottom" style={{zIndex:Robowhiz ? '11' : ''}} src={bannerbottom} alt="" />
        <Row className="bannerrow" style={{backgroundImage:Robowhiz && isMobile ? `url(${robomobbannerimg})` : '',minHeight:is395 ? "650px" : ""}}>
          <Col className="bannercol1">
            <span className="bannertitle animate__animated animate__fadeInDown">
              {Robowhiz ? "Robo Whiz" : "Build, Code, Design, Create"}
            </span>
            <br />
            <span className="bannercontent animate__animated animate__fadeInDown">
            {Robowhiz ? "Unleash your child's inner inventor with our robotics programs." : "Empowering younger minds with critical skills for the future "}
            </span>
            <br />
            <Button className="bannerbutton" onClick={handleBookFreeClassClick}>Book Your Free Demo</Button>
          </Col>
          <Col className="bannercol2">
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ position: "relative" }}
          >
            <img src={bannerimg} alt="" className="imageone" />
            <img src={bannerimganim} alt="" className="imagetwo animate__animated animate__zoomIn" style={isHovering ? divStyle : {}} />
          </div>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

export default Banner;
