import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import p1 from "./robowhizimages/Group 570.svg";
import p2 from "./robowhizimages/Group 576.svg";
import p3 from "./robowhizimages/Group 578.svg";
import p4 from "./robowhizimages/Group 579.svg";
import rb from "./robowhizimages/robotics-100 1.svg";
import Slider from "react-slick";
function Programs() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobile = screenWidth < 480;

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const settings = {
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    arrows: false,
  };
  return (
    <>
      <Container fluid className="programwrapper">
        <Row className="programsrow">
          <img src={rb} alt="" />
          <span className="title">
            Check out our
            <br />
            <span className="bold">Programs</span>
          </span>
        </Row>
        {!isMobile ? (
          <Row className="programscardrow">
            <Col className="card1">
              <span className="cardtitle">Beginner</span>
              <br />
              <span className="price">$400</span>
              <br />
              <span className="lessons">24 sessions</span>
              <br />
              <Button className="getstarted">Get started</Button>
              <br />
              <span className="modules">Modules :</span>
              <br />
              <ul>
                <li>SIMPLE, INTUITIVE PLUG & LEARN</li>
                <li>LEARNING COMPUTER SCIENCE & ENGINEERING CONCEPTS</li>
                <li>BASIC ELECTRONICS</li>
                <li>MICRO COMPUTER & PROGRAMMING</li>
              </ul>
            </Col>
            <Col className="card1">
              <span className="cardtitle">Intermediate</span>
              <br />
              <span className="price">$600</span>
              <br />
              <span className="lessons">24 sessions</span>
              <br />
              <Button className="getstarted">Get started</Button>
              <br />
              <span className="modules">Modules :</span>
              <br />
              <ul>
                <li>LEGO ENGINEERING & CODING</li>
                <li>MICROCONTROLLER & SENSORS</li>
                <li>INTERNET OF THINGS</li>
                <li>ARTIFICIAL INTELLIGENCE</li>
              </ul>
            </Col>
            <Col className="card1">
              <span className="cardtitle">Advanced</span>
              <br />
              <span className="price">$800</span>
              <br />
              <span className="lessons">24 sessions</span>
              <br />
              <Button className="getstarted">Get started</Button>
              <br />
              <span className="modules">Modules :</span>
              <br />
              <ul>
                <li>raspberry pi configuration and python</li>
                <li>emedded system</li>
                <li>uav (unmanned aerial vehicle)</li>
                <li>ar & vr technology</li>
              </ul>
            </Col>
          </Row>
        ) : (
          <Slider {...settings} className="programscardrow">
            {/* <Row className="programscardrow"> */}
            <Col className="card1">
              <span className="cardtitle">Beginner</span>
              <br />
              <span className="price">$400</span>
              <br />
              <span className="lessons">24 sessions</span>
              <br />
              <Button className="getstarted">Get started</Button>
              <br />
              <span className="modules">Modules :</span>
              <br />
              <ul>
                <li>SIMPLE, INTUITIVE PLUG & LEARN</li>
                <li>LEARNING COMPUTER SCIENCE & ENGINEERING CONCEPTS</li>
                <li>BASIC ELECTRONICS</li>
                <li>MICRO COMPUTER & PROGRAMMING</li>
              </ul>
            </Col>
            <Col className="card1">
              <span className="cardtitle">Intermediate</span>
              <br />
              <span className="price">$600</span>
              <br />
              <span className="lessons">24 sessions</span>
              <br />
              <Button className="getstarted">Get started</Button>
              <br />
              <span className="modules">Modules :</span>
              <br />
              <ul>
                <li>LEGO ENGINEERING & CODING</li>
                <li>MICROCONTROLLER & SENSORS</li>
                <li>INTERNET OF THINGS</li>
                <li>ARTIFICIAL INTELLIGENCE</li>
              </ul>
            </Col>
            <Col className="card1">
              <span className="cardtitle">Advanced</span>
              <br />
              <span className="price">$800</span>
              <br />
              <span className="lessons">24 sessions</span>
              <br />
              <Button className="getstarted">Get started</Button>
              <br />
              <span className="modules">Modules :</span>
              <br />
              <ul>
                <li>raspberry pi configuration and python</li>
                <li>emedded system</li>
                <li>uav (unmanned aerial vehicle)</li>
                <li>ar & vr technology</li>
              </ul>
            </Col>
            {/* </Row> */}
          </Slider>
        )}
      </Container>
      <Container className="posterwrapper" fluid>
        <Row className="postorsrow">
          <Col className="postorscol">
            <div className="content">
              <img
                src={p1}
                alt=""
                style={{
                  width: "74.366px",
                  height: "61.934px",
                  margin: "0 auto 0",
                }}
              />
              <span className="heading">1M+</span>
              <br />
              <span className="description">Future Leaders</span>
            </div>
            <div className="stick"></div>
          </Col>
          <Col className="postorscol">
            <div className="content">
              <img
                src={p2}
                alt=""
                style={{
                  width: "62.893px",
                  height: "55px",
                  margin: "0 auto 20px",
                }}
              />

              <span className="heading">500k+</span>
              <br />
              <span className="description">Monthly Class</span>
            </div>
            <div className="stick"></div>
          </Col>
          <Col className="postorscol">
            <div className="content">
              <img
                src={p3}
                alt=""
                style={{
                  width: "48.684px",
                  height: "48.684px",
                  margin: "0 auto 20px",
                }}
              />

              <span className="heading">30+</span>
              <br />
              <span className="description">Countries</span>
            </div>
            <div className="stick"></div>
          </Col>
          <Col className="postorscol">
            <div className="content">
              <img
                src={p4}
                alt=""
                style={{
                  width: "47.822px",
                  height: "45.239px",
                  margin: "0 auto 20px",
                }}
              />

              <span className="heading">4.8/5</span>
              <br />
              <span className="description">Global Rating</span>
            </div>
            <div
              className="stick"
              style={{ visibility: "hidden" }}
              id="sticklast"
            ></div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Programs;
