import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import "./Navbar.css";
import Logo from "./commonimages/image 2.svg";
import Toggle from "./commonimages/Group 435.svg";
import MyContactForm from "../reusable/MyContactForm";
import { Link } from "react-router-dom";
import styled from "styled-components";
function NavBar() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const collapseRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const Modal = ({ onClose, videoUrl }) => {
    return (
      <ModalWrapper>
        <ModalContent>
          <MyContactForm popup={true} />
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalContent>
      </ModalWrapper>
    );
  };
  const hideBars = () => {
    // Set the class attribute of the div element to "collapse navbar-collapse"
    if (collapseRef.current) {
      collapseRef.current.setAttribute("class", "collapse navbar-collapse");
    }
  };

  const isMobile = screenWidth < 480;
  const istoggle = screenWidth < 992;
  // Handle the click event of the "Book Free Class" button
  const handleBookFreeClassClick = () => {
    const speakToTeamSection = document.getElementById("speakToTeamSection");
    if (speakToTeamSection) {
      speakToTeamSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    // Function to update screen width in state
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add an event listener to update screen width on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Navbar expand="lg" className="bg-body-tertiary" collapseOnSelect>
      <Container style={{ maxWidth: "1200px" }}>
      {isOpen && <Modal onClose={closeModal} />}

        {!isMobile && (
          <>
            {/* {isOpen && <Modal onClose={closeModal} />} */}
            <Navbar.Brand href="/">
              <img src={Logo} alt="" />
            </Navbar.Brand>
            {!istoggle && (
              <Navbar.Collapse
                id="basic-navbar-nav"
                ref={collapseRef}
                className="bigsc"
              >
                <Nav
                  className="me-auto"
                  style={{ gap: "12px", margin: "auto" }}
                >
                  <Link
                    to="/"
                    style={{ textDecoration: "none" }}
                    className="links"
                    onClick={hideBars}
                  >
                    Home
                  </Link>
                  {/* <Link
                    to="/robotics-for-kids"
                    style={{ textDecoration: "none" }}
                    className="links"
                    onClick={hideBars}
                  >
                    RoboWhiz
                  </Link> */}
                  <Link
                    to="/why-coding-school"
                    style={{ textDecoration: "none" }}
                    className="links"
                    onClick={hideBars}
                  >
                    Coding school
                  </Link>
                  <Link
                    to="/contact-us"
                    style={{ textDecoration: "none" }}
                    className="links"
                    onClick={hideBars}
                  >
                    Contact Us
                  </Link>
                </Nav>
              </Navbar.Collapse>
            )}
            <div className="toggle">
              <Button className="button1" onClick={openModal}>
                Book Free Class
              </Button>
              <Button className="button1" onClick={handleBookFreeClassClick}>
                Join Now
              </Button>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                style={{ border: "none" }}
              >
                <img src={Toggle} alt="" />
              </Navbar.Toggle>
            </div>
          </>
        )}
        {isMobile && (
          <>
          
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Navbar.Brand href="/">
                <img src={Logo} alt="" style={{ maxWidth: "140px" }} />
              </Navbar.Brand>

              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                style={{ border: "none" }}
              >
                <img src={Toggle} alt="" />
              </Navbar.Toggle>
            </div>
            <div className="toggle" style={{ margin: "1rem auto" }}>
              <Button className="button1" onClick={openModal}>
                Book Free Class
              </Button>
              <Button className="button1" onClick={handleBookFreeClassClick}>
                Join Now
              </Button>
            </div>
          </>
        )}
        {istoggle && (
          <Navbar.Collapse id="basic-navbar-nav" ref={collapseRef}>
            <Nav className="me-auto">
              <Link
                to="/"
                style={{ textDecoration: "none" }}
                className="links"
                onClick={hideBars}
              >
                Home
              </Link>
              <Link
                to="/why-coding-school"
                style={{ textDecoration: "none" }}
                className="links"
                onClick={hideBars}
              >
                Coding school
              </Link>
              {/* <Link
                to="/our-programs"
                style={{ textDecoration: "none" }}
                className="links"
                onClick={hideBars}
              >
                Our Programs
              </Link> */}
              <Link
                to="/contact-us"
                style={{ textDecoration: "none" }}
                className="links"
                onClick={hideBars}
              >
                Contact Us
              </Link>
            </Nav>
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
}

export default NavBar;
// css
const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11111111;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  @media (max-width: 1024px) {
    /* margin-top: 6rem; */
  }
`;

const ModalContent = styled.div`
  position: relative;
`;
const CloseButton = styled.button`
  margin-top: 10px;
  border: none;
  background: red;
  color: #fff;
  position: absolute;
  top: -13%;
  right: -4%;
`;
