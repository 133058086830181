import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import bannerimg from "../whycodingschool/csimages/Group 810.png";
import bannertop from "../Homescreen/homeimages/Group 1 (27).svg";
import robowhiztop from "../Homescreen/homeimages/Group 560.svg";
import mobview from "../contactus/contactimg/Group 1 (16).png";
import upbgimg from "../contactus/contactimg/Group 1 (15).png";
// import bannerbottom from "../Homescreen/homeimages/Group 590 (1).svg";
import bannerbottom from "../whycodingschool/csimages/Group 807.svg";
// import './Codingschool.css'
function Banner({ Robowhiz }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const isMobile = screenWidth < 480;
  const handleBookFreeClassClick = () => {
    const speakToTeamSection = document.getElementById("speakToTeamSection");
    if (speakToTeamSection) {
      speakToTeamSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    // Function to update screen width in state
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add an event listener to update screen width on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Container fluid className="cntctbanner">
      <Row className="ourprgrmbannerrowwrapper">
        <Row className="cntctbannerrow">
          <Col className="cntctbannercol1">
            <span className="cntctbannertitle" style={{margin:isMobile && '4rem 0 2rem'}}>Unlock your child’s potential in coding{" "}</span>
            <br />
            {!isMobile && (
              <>
                <Button className="cntctbannerbutton" onClick={handleBookFreeClassClick}>
                  Book Your Free Demo
                </Button>
              </>
            )}
          </Col>
          <Col className="cntctcsbannercol2" style={{position:isMobile ? 'relative' : ''}}>
            {!isMobile && (
              <>
                <img src={upbgimg} alt="" />
              </>
            )}
            {isMobile && (
              <>
                <img src={mobview} alt="" style={{ marginBottom: "-2rem" }} />
                <br />
                <div className="mobbuttonwrap">
                <Button className="cntctbannerbutton" style={{width:'100%'}} onClick={handleBookFreeClassClick}>
                  Book Your Free Demo
                </Button>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

export default Banner;

// import React from "react";
// import { Button, Col, Container, Row } from "react-bootstrap";
// import bannerimg from "./opimages/Group 1 (12).png";
// import bannertop from "../Homescreen/homeimages/Group 1 (27).svg";
// import robowhiztop from "../Homescreen/homeimages/Group 560.svg";
// // import bannerbottom from "../Homescreen/homeimages/Group 590 (1).svg";
// import bannerbottom from "../whycodingschool/csimages/Group 807.svg";
// import "./OurPrograms.css";
// function Banner({ Robowhiz }) {
//   return (
//     <Container fluid className="banner">
//       {/* <div>
//         <img src={bannerbkd} alt="" />
//       </div> */}
//       <Row className="bannerrowwrapper">
//         <img
//           className="bannertop"
//           src={Robowhiz ? robowhiztop : bannertop}
//           alt=""
//           style={{ transform: Robowhiz ? "translate(0%, 40px)" : "" }}
//         />
//         <img
//           className="csbannerbottom"
//           //   style={{ zIndex: Robowhiz ? "11" : "11",transform:'translate(10%,34%)' }}
//           src={bannerbottom}
//           alt=""
//         />
//         <Row className="bannerrow">
//           <Col className="bannercol1">
//             <span className="bannertitle">
//               Unlock your child’s potential in coding{" "}
//             </span>
//             <br />
//             <Button className="bannerbutton">Book Your Free Demo</Button>
//           </Col>
//           <Col className="csbannercol2">
//             <img src={bannerimg} alt="" />
//           </Col>
//         </Row>
//       </Row>
//     </Container>
//   );
// }

// export default Banner;
