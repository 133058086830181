import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import GE from './robowhizimages/medal-100 1.svg'
import Fc from './robowhizimages/lesson-100 1.svg'
import Aga from './robowhizimages/earth-globe-100 1.svg'
function WhyChoose() {
  return (
    <Container fluid className='robowrapper'>
        <Row className="whychoose">
        <div>

        <Row className="learningcontent">
          <span className="codestitle">
            Why choose<span>Robo Whiz</span>
          </span>
        </Row>
        <Row className="choosecards" style={{minHeight:'220px'}}>
          <Col className="robochoosecard">
            <img src={GE} alt="" className='geimage'/>
            <span className="subtitle">Top 1% Global Educators</span>
            <br />
            
            <span className="subcontent">
              Thoroughly trained to cultivate children's interest, curiosity,
              and learning
            </span>
          </Col>
          <Col className="robochoosecard">
          <img src={Fc} alt="" className='fcimage'/>

            <span className="subtitle">Futuristic Curriculum</span>
            <br />
            <span className="subcontent">
            Crafted by renowned industry experts and leading universities
            </span>
          </Col>
          <Col className="robochoosecard">
          <img src={Aga} alt="" className='agaimage'/>

            <span className="subtitle">Access to the Global Arena</span>
            <br />
            <span className="subcontent">
            Get access to our international hackathons and compete against 1M+ future leaders
            </span>
          </Col>
        </Row>
        </div>

      </Row>
    </Container>
  )
}

export default WhyChoose