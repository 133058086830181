import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import img from "./robowhizimages/Group 1 (10).png";
import quote from "./robowhizimages/material-symbols_format-quote-rounded.svg";
import star from "./robowhizimages/ic_twotone-star-border.svg";
import human from "./robowhizimages/Group 2.svg";
import Slider from "react-slick";
import RightArrow from "./RightArrow";
import LeftArrow from "./LeftArrow";
function Testimonials() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobile480 = screenWidth < 480;
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    // arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <LeftArrow testimonials="true" />,
    nextArrow: <RightArrow testimonials="true" />,
    // nextArrow: <RightArw />,
  };
  return (
    <Container fluid className="testimonailswrapper">
      <Row className="testimonailstitle">
        <span>Testimonials</span>
      </Row>
      <Slider {...settings} className="tslider">
        <div>
          <Row className="tcontent">
            <Col className="col1">
              {/* <img src={img} alt="" /> */}
              <iframe
            width="100%"
            height="315"
            // src="https://www.youtube.com/embed/0zmHmWuRDgY"
            src="https://www.youtube.com/embed/roqgNxr67pY"
            title="YouTube video player"//
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{borderRadius:'12px'}}
          />
            </Col>
            <Col className="col2">
              <img src={quote} alt="" />
              <br className="brquote" />
              <span className="quote">
              Burhanuddin Mustafa, a student at MSB Private School in Dubai, shares his enthusiasm for the Robotics classes taught by the Coding School.
              </span>
              <br />
              {isMobile480 ? (
                <div className="tname">
                  <div className="mobtname">
                    <img src={human} alt="" />
                    <div>
                      <span className="pname">Burhanuddin Mustafa</span>
                      <br />
                      <span className="pdesc">student</span>
                    </div>
                  </div>
                  <div className="trating">
                    <img src={star} alt="" />
                    <span>4.5</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="tname">
                    <div>
                      <span className="pname">Burhanuddin Mustafa</span>
                      <br />
                      <span className="pdesc">student</span>
                    </div>
                    <div className="trating">
                      <img src={star} alt="" />
                      <span>4.5</span>
                    </div>
                  </div>
                  <br />
                  <img src={human} alt="" />
                </>
              )}
            </Col>
          </Row>
        </div>
        <div>
          <Row className="tcontent">
            <Col className="col1">
            <iframe
            width="100%"
            height="315"
            // src="https://www.youtube.com/embed/0zmHmWuRDgY"
            src="https://www.youtube.com/embed/IYMePtja_mE"
            title="YouTube video player"//
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{borderRadius:'12px'}}

          />
            </Col>
            <Col className="col2">
              <img src={quote} alt="" />
              <br className="brquote" />
              <span className="quote">
              Fatima Bohra, a student at MSB Private School in Dubai, conveys her love for technology and her enthusiasm for robotics classes conducted by the Coding School, underscoring her dedication to utilising technology for the cause of environmental sustainability.
              </span>
              <br />
              {isMobile480 ? (
                <div className="tname">
                  <div className="mobtname">
                    <img src={human} alt="" />
                    <div>
                      <span className="pname">Fatima Bohra</span>
                      <br />
                      <span className="pdesc">student</span>
                    </div>
                  </div>
                  <div className="trating">
                    <img src={star} alt="" />
                    <span>4.5</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="tname">
                    <div>
                      <span className="pname">Fatima Bohra</span>
                      <br />
                      <span className="pdesc">student</span>
                    </div>
                    <div className="trating">
                      <img src={star} alt="" />
                      <span>4.5</span>
                    </div>
                  </div>
                  <br />
                  <img src={human} alt="" />
                </>
              )}
            </Col>
          </Row>
        </div>
        <div>
          <Row className="tcontent">
            <Col className="col1">
            <iframe
            width="100%"
            height="315"
            // src="https://www.youtube.com/embed/0zmHmWuRDgY"
            src="https://www.youtube.com/embed/PUQiXOwFoFU"
            title="YouTube video player"//
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{borderRadius:'12px'}}
          />
            </Col>
            <Col className="col2">
              <img src={quote} alt="" />
              <br className="brquote" />
              <span className="quote">
              Listen to the thoughts of Abhinav Krishna, a Grade 5 student, as he shares his experiences with Coding School classes and how he makes the most of his time in robotics.
              </span>
              <br />
              {isMobile480 ? (
                <div className="tname">
                  <div className="mobtname">
                    <img src={human} alt="" />
                    <div>
                      <span className="pname">Abhinav Krishna</span>
                      <br />
                      <span className="pdesc">student</span>
                    </div>
                  </div>
                  <div className="trating">
                    <img src={star} alt="" />
                    <span>4.5</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="tname">
                    <div>
                      <span className="pname">Abhinav Krishna</span>
                      <br />
                      <span className="pdesc">student</span>
                    </div>
                    <div className="trating">
                      <img src={star} alt="" />
                      <span>4.5</span>
                    </div>
                  </div>
                  <br />
                  <img src={human} alt="" />
                </>
              )}
            </Col>
          </Row>
        </div>
        {/* <div>
          <Row className="tcontent">
            <Col className="col1">
              <img src={img} alt="" />
            </Col>
            <Col className="col2">
              <img src={quote} alt="" />
              <br className="brquote" />
              <span className="quote">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </span>
              <br />

              <div className="tname">
                <div>
                  <span className="pname">John Doe</span>
                  <br />
                  <span className="pdesc">lorem ipsum</span>
                </div>
                <div className="trating">
                  <img src={star} alt="" />
                  <span>4.5</span>
                </div>
              </div>
              <br />
              <img src={human} alt="" />
            </Col>
          </Row>
        </div> */}
      </Slider>
    </Container>
  );
}

export default Testimonials;
