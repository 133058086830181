import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function Curriculum() {
  return (
    <Container fluid className="curriculumwrapper">
      <Row className="curriculumtitlerow">
        <span className="ctitle">
          Coding School <span>Curriculum</span>
        </span>
        <br />
        <span className="ccontent">
          Our research-based, exclusive curriculum is individualised at every
          level, from beginner to advanced to professional levels.
        </span>
      </Row>
      <Row className="boxrow">
        <Col className="cbox">
            <span>While encouraging and guiding students to produce creative projects like websites, animations, and apps, our curriculum teaches the principles of coding—logic, structure, sequence, and algorithmic thinking.</span>
        </Col>
        <Col className="cbox">
            <span>
            Additionally, our program covers an AI curriculum. Students will take on the concept of AI from its core pillars up to coding and implementing Machine Learning models and programs.
            </span>
        </Col>
        <Col className="cbox">
            <span>
            A solid foundation at an early age can help children better understand robots and technology. At Coding School, we also follow a robotics curriculum that aims to enhance students’ creative abilities by using real-world examples of how things work to facilitate learning.
            </span>
        </Col>
        <Col className="cbox">
            <span>
            We also follow a uniquely designed comprehensive curriculum which introduces a child to the world of Data Science, offering children the best opportunity to learn analytical skills at an early age.
            </span>
        </Col>
      </Row>
    </Container>
  );
}

export default Curriculum;
