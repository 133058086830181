import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import bannerimg from "../whycodingschool/csimages/Group 810.png";
import bannertop from "../Homescreen/homeimages/Group 1 (27).svg";
import robowhiztop from "../Homescreen/homeimages/Group 560.svg";
import mobview from "./contactimg/Group 861.png";
// import bannerbottom from "../Homescreen/homeimages/Group 590 (1).svg";
import bannerbottom from "../whycodingschool/csimages/Group 807.svg";
// import './Codingschool.css'
function Banner({ Robowhiz,cs }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const isMobile = screenWidth < 480;
  const handleBookFreeClassClick = () => {
    const speakToTeamSection = document.getElementById("connectwrapper");
    if (speakToTeamSection) {
      speakToTeamSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    // Function to update screen width in state
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add an event listener to update screen width on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Container fluid className="cntctbanner">
      <Row className="cntctbannerrowwrapper">
        <img
          className="cntctbannertop"
          src={Robowhiz ? robowhiztop : bannertop}
          alt=""
          style={{ transform: Robowhiz ? "translate(0%, 40px)" : "" }}
        />
        <Row className="cntctbannerrow">
          <Col className="cntctbannercol1" style={{marginTop:isMobile ? '3rem' : ''}}>
          {cs ? (
              <>
                <span className="cntctbannertitle">Why <br /> Coding School</span>
            <br />
            <span className="cntctbannercontent" style={{fontSize:isMobile ? "19px" : "24px",fontWeight:'400',lineHeight:isMobile ? '27px' : '34px'}}>
              Coding School (CS) is a division of Airtics Education dedicated to
              unlocking the full potential of children in the fields of AI.
            </span>
              </>
            ) : (
              <>
                <span className="cntctbannercontent">Contact Us</span>
                <span className="cntctbannertitle">
                  We’d love to hear from you
                </span>
              </>
            )}
            {/* <span className="cntctbannercontent">Contact Us</span>
            <span className="cntctbannertitle">We’d love to hear from you</span> */}
            <br />
            {!isMobile && (
              <>
                <Button className="cntctbannerbutton" onClick={handleBookFreeClassClick}>
                  Book Your Free Demo
                </Button>
              </>
            )}
          </Col>
          <Col className="cntctcsbannercol2" style={{position:isMobile ? 'relative' : ''}}>
            {!isMobile && (
              <>
                <img src={bannerimg} alt="" />
              </>
            )}
            {isMobile && (
              <>
                <img src={mobview} alt="" style={{ marginBottom: "-2rem" }} />
                <br />
                <div className="mobbuttonwrap">
                <Button className="cntctbannerbutton" style={{width:'100%'}} onClick={handleBookFreeClassClick}>
                  Book Your Free Demo
                </Button>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

export default Banner;
