import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
// import image1 from "../Homescreen/homeimages/Group 617.png";
import arrow from "../Homescreen/homeimages/tabler_chevron-down.svg";

function Innovator({ champ, gaming, metaverse }) {
  const [btn1, setBtn1] = useState(true);
  const [btn2, setBtn2] = useState(false);
  const [btn3, setBtn3] = useState(false);
  const [btn4, setBtn4] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobile = screenWidth < 510;
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const ButtonOneHandler = () => {
    setBtn1(true);
    setBtn2(false);
    setBtn3(false);
    setBtn4(false);
  };
  const ButtonTwoHandler = () => {
    setBtn1(false);
    setBtn2(true);
    setBtn3(false);
    setBtn4(false);
  };
  const ButtonThreeHandler = () => {
    setBtn1(false);
    setBtn2(false);
    setBtn3(true);
    setBtn4(false);
  };
  const ButtonFourHandler = () => {
    setBtn1(false);
    setBtn2(false);
    setBtn3(false);
    setBtn4(true);
  };
  return (
    <Container fluid className="innovatorwrapper">
      <Row className="innovatorrow">
        <span className="innovatorheading">
          Begin your child's exciting journey to <br />
          <span className="dheadsubspan">become a future innovator</span>
        </span>
      </Row>
      <Row className={`${isMobile ? "providerow2" : "innovatorrow2"}`}>
        {champ ? (
          <Button
            className="innovatorbutton"
            style={{
              background: btn1 ? "#fff" : "#e5e2ec",
              borderRadius: "0",
              width: champ && !isMobile ? "33.33%" : "",
            }}
            onClick={ButtonOneHandler}
          >
            <span>Basic Programming</span>
            <img
              src={arrow}
              alt=""
              className="arrow"
              style={{ transform: btn1 ? "" : "rotate(90deg)" }}
            />
            <div
              className="line"
              style={{ display: btn1 || isMobile ? "none" : "" }}
            />
          </Button>
        ) : gaming ? (
          <Button
            className="innovatorbutton"
            style={{
              background: btn1 ? "#fff" : "#e5e2ec",
              borderRadius: "0",
              width: gaming && !isMobile ? "100%" : "",
            }}
            onClick={ButtonOneHandler}
          >
            <span>Game Development </span>
            <img
              src={arrow}
              alt=""
              className="arrow"
              style={{ transform: btn1 ? "" : "rotate(90deg)" }}
            />
            <div
              className="line"
              style={{ display: btn1 || isMobile ? "none" : "" }}
            />
          </Button>
        ) : metaverse ? (
          <Button
            className="innovatorbutton"
            style={{
              background: btn1 ? "#fff" : "#e5e2ec",
              borderRadius: "0",
              width: metaverse && !isMobile ? "50%" : "",
            }}
            onClick={ButtonOneHandler}
          >
            <span>Artificial intelligence</span>
            <img
              src={arrow}
              alt=""
              className="arrow"
              style={{ transform: btn1 ? "" : "rotate(90deg)" }}
            />
            <div
              className="line"
              style={{ display: btn1 || isMobile ? "none" : "" }}
            />
          </Button>
        ) : (
          <Button
            className="innovatorbutton"
            style={{
              background: btn1 ? "#fff" : "#e5e2ec",
              borderRadius: "0",
            }}
            onClick={ButtonOneHandler}
          >
            <span>Junior Coder</span>
            <img
              src={arrow}
              alt=""
              className="arrow"
              style={{ transform: btn1 ? "" : "rotate(90deg)" }}
            />
            <div
              className="line"
              style={{ display: btn1 || isMobile ? "none" : "" }}
            />
          </Button>
        )}
        {/* {btn1 && isMobile && (
          <>
            <Row className="innovatorow3">
              <Col className="innovatorcols">
                <Button className="levelbutton">Level 1</Button>
                <span
                  className="programhighlightstitle"
                  style={{ textAlign: "center" }}
                >
                  Program highlights
                </span>
                <ul className="phlists">
                  <li>
                    Students learn to articulate their thoughts through code and
                    technology
                  </li>
                  <li>
                    Communicating mathematically to the computer/device via code
                  </li>
                  <li>Learn to get familiar with basic programming concepts</li>
                </ul>
                <span className="programhighlightstitle">
                  Learning outcomes
                </span>
                <span className="locontent">
                  <span>L01: </span>Learners implement and evaluate procedures
                  for exploring and solving issues using mathematical knowledge,
                  logic, and abilities.
                </span>
                <span className="locontent">
                  <span>L02: </span>Getting familiar with basic programming
                  concepts like input, output, variables, conditions and loops
                </span>
                <span className="locontent">
                  <span>L03: </span>Learn to install and download multiple
                  software that helps make games and animations using block
                  coding.
                </span>
                <span className="locontent">
                  <span>L04: </span>K-turtle interface and fundamental
                  instructions are needed to accomplish many transitions.
                </span>
                <span className="programhighlightstitle">
                  Improvement areas
                </span>
                <ul className="phlists">
                  <li>Computational thinking</li>
                  <li>Mathematical knowledge</li>
                  <li>Creativity</li>
                </ul>
              </Col>
              <Col className="innovatorcols">
                <Button className="levelbutton">Level 2</Button>
                <span
                  className="programhighlightstitle"
                  style={{ textAlign: "center" }}
                >
                  Program highlights
                </span>
                <ul className="phlists">
                  <li>Bringing ideas from conception to realisation</li>
                  <li>Comprehensive skills in the Microsoft Office suite</li>
                  <li>
                    Demonstrating a creative mentality (rather than a fixed
                    attitude)
                  </li>
                </ul>
                <span className="programhighlightstitle">
                  Learning outcomes
                </span>
                <span className="locontent">
                  <span>L01: </span>Utilising fundamental programming components
                  in k-turtle regarding degrees and directions to design
                  programs.
                </span>
                <span className="locontent">
                  <span>L02: </span>Discover methods to employ MS-Word, MS-Excel
                  and MS-PowerPoint.
                </span>
                <span className="locontent">
                  <span>L03: </span>Examine advanced alternatives to produce
                  animation and games using scratch and programming concepts.
                </span>
                <span className="locontent">
                  <span>L04: </span>Simple picture editing using PIXLR.
                </span>
                <span className="programhighlightstitle">
                  Improvement areas
                </span>
                <ul className="phlists">
                  <li>Picture Editing tools</li>
                  <li>Programming Tesselations</li>
                  <li>Microsoft Office Suite</li>
                </ul>
              </Col>
              <Col className="innovatorcols">
                <Button className="levelbutton">Level 3</Button>
                <span
                  className="programhighlightstitle"
                  style={{ textAlign: "center" }}
                >
                  Program highlights
                </span>
                <ul className="phlists">
                  <li>
                    Understand the programming fundamentals utilizing numerous
                    coding platforms.
                  </li>
                  <li>
                    Get an advanced degree of knowledge in video and photo
                    editing.
                  </li>
                  <li>
                    Discuss and produce ideas for the development and
                    implementation of solutions and projects.
                  </li>
                </ul>
                <span className="programhighlightstitle">
                  Learning outcomes
                </span>
                <span className="locontent">
                  <span>L01: </span>Recording and editing videos using various
                  tools to build help design pictures and videos on an advanced
                  level.
                </span>
                <span className="locontent">
                  <span>L02: </span>Learners will study ideas like algorithms,
                  machine learning, and neural networks as a fundamental
                  introduction.
                </span>
                <span className="locontent">
                  <span>L03: </span>Advanced components of games and apps are
                  developed using block coding and programming languages.
                </span>
                <span className="programhighlightstitle">
                  Improvement areas
                </span>
                <ul className="phlists">
                  <li>Apps and games development</li>
                  <li>Managing information and thinking</li>
                  <li>Artificial Intelligence</li>
                </ul>
              </Col>
            </Row>
          </>
        )} */}
        {btn1 && isMobile && champ ? (
        <>
          <Row className="innovatorow3">
            <div className="basicprgrm">
              <span className="coursedesctitle">Course Description: </span>
              <br />
              <span className="coursedesccontent">
                Basic Programming is a comprehensive foundational program that
                introduces young learners to the world of programming and
                digital technology. Over 16 hours, participants will embark on a
                journey through interactive platforms such as ScratchJr and
                Tynker, while also gaining a basic understanding of data through
                Google Sheets and website design via Google Sites. The course is
                designed to harness kids' natural curiosity and foster
                creativity, ensuring that learning remains an engaging and
                enjoyable experience. Through a series of structured modules,
                children will create animations, understand data, and even
                launch their personal webpages, paving the way for further
                exploration in the digital realm.
              </span>
              <br />
              <span
                className="coursedesctitle"
                style={{ marginBottom: "10px" }}
              >
                Learning outcomes
              </span>
              <span
                className="coursedesccontent"
                style={{ marginBottom: "7px" }}
              >
                <span className="coursedesctitle" style={{ fontSize: "18px" }}>
                  L01 :{" "}
                </span>
                Students will master foundational programming using ScratchJr
                and Tynker.
              </span>
              <span
                className="coursedesccontent"
                style={{ marginBottom: "7px" }}
              >
                <span className="coursedesctitle" style={{ fontSize: "18px" }}>
                  L02 :{" "}
                </span>
                Learners will become proficient with important digital tools
                like keyboards, mouse, and Google Sheets.
              </span>
              <span
                className="coursedesccontent"
                style={{ marginBottom: "7px" }}
              >
                <span className="coursedesctitle" style={{ fontSize: "18px" }}>
                  L03 :{" "}
                </span>
                Participants will construct personal webpages using Google
                Sites, knowing key design principles.
              </span>
              <span
                className="coursedesccontent"
                style={{ marginBottom: "30px" }}
              >
                <span className="coursedesctitle" style={{ fontSize: "18px" }}>
                  L04 :{" "}
                </span>
                Children will cultivate collaborative skills and reflective
                practices via project sharing and feedback.
              </span>
              <span className="coursedesctitle">Tools Used:</span>
              <br />
              <ul
                className="coursedesccontent"
                style={{ marginBottom: "3rem" }}
              >
                <li>ScratchJr</li>
                <li>Keyboard and Mouse</li>
                <li>Tynker</li>
                <li>Google Sheets</li>
                <li>Google Sites</li>
                <li>Scratch3 (introductory)</li>
              </ul>
              <span className="coursedesctitle">Course Modules: </span>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td className="coursedesccontent">
                      Introduction to Programming Concepts: ScratchJr
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td className="coursedesccontent">
                      Exploring Keyboard and Mouse
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td className="coursedesccontent">
                      Data and Simple Calculations: Google Sheets
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td className="coursedesccontent">
                      Building and Sharing Our Work: Google Sites & Scratch3
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Row>
        </>
      ) : btn1 && isMobile && gaming ?(
        <>
        {/* Game developement */}
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
                Base Level (Ages 8-10):
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              This base level aims to introduce young learners to the fascinating world of game development. Through engaging platforms like Scratch3 and Construct, students will create their own simple games, understanding the core concepts behind game design and mechanics.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Understand different types of games and their significance in the digital landscape.
              </span>
              <span className="locontent">
                <span>L02: </span>Familiarize with the Scratch3 and Construct interfaces and tools.
              </span>
              <span className="locontent">
                <span>L03: </span>Develop basic games on Scratch3, including mechanics like scoring and levels.
              </span>
              <span className="locontent">
                <span>L04: </span>Transition to Construct to design a simple platformer game.
              </span>
              <span className="locontent">
                <span>L05: </span>Showcase a game of their choice, applying learned concepts.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Scratch3</li>
                <li>Construct</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to Game Development
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                     Basic Game Mechanics with Scratch3
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Moving to Construct
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                     Advanced Concepts and Final Project
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Intermediate Level (Ages 11-13)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Diving deeper into the game development realm, this intermediate level exposes students to the scientific concepts behind game creation. They'll work with Game Lab and delve into C# programming, which forms the backbone of many contemporary games.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Comprehend advanced game development concepts like game loops, events, and input handling.
              </span>
              <span className="locontent">
                <span>L02: </span>Gain proficiency in Game Lab's interface and scripting.
              </span>
              <span className="locontent">
                <span>L03: </span>Grasp C# fundamentals and its application in game logic and mechanics.
              </span>
              <span className="locontent">
                <span>L04: </span>Understand object-oriented programming and its relevance in gaming.
              </span>
              <span className="locontent">
                <span>L05: </span>Design and present a game project, applying advanced mechanics and logic.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Game Lab</li>
                <li>C# programming language</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Deep Dive into Game Concepts
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Programming Basics with C#
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advanced Game Lab Development
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Wrapping Up with C# and Game Lab
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Advanced Level (Ages 14-16)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Designed for seasoned learners, the advanced level dives into professional game development using Unity3D. Students will explore 3D game development, script their own game mechanics, and implement sophisticated design principles to craft a compelling gaming experience.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Navigate the Unity3D interface, understanding essential components like scenes, game objects, and assets.
              </span>
              <span className="locontent">
                <span>L02: </span>Delve into 3D game development basics, from camera placement to importing 3D models.
              </span>
              <span className="locontent">
                <span>L03: </span>Master Unity's scripting using C#, manipulating game objects and interactions.
              </span>
              <span className="locontent">
                <span>L04: </span>Implement animation, sound, and user experience principles in game design.
              </span>
              <span className="locontent">
                <span>L05: </span>Conceptualize, design, and present a comprehensive Unity3D game project.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Unity3D</li>
                <li>C# programming language within the Unity environment.</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to Unity3D
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                     Scripting in Unity with C#
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advanced Unity Features
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Final Unity Projects
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : btn1 && isMobile && metaverse ? (
        <>
        {/* metaverse */}
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
                Base Level (Ages 8-10):
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Introduce young learners to the intriguing world of artificial intelligence (AI) through platforms tailored for beginners. In this level, students will discover basic AI concepts and employ Scratch3 and Pictoblox to design simple AI-based projects.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Recognize what AI is and its applications in the real world.
              </span>
              <span className="locontent">
                <span>L02: </span>Understand basic programming concepts using Scratch3.
              </span>
              <span className="locontent">
                <span>L03: </span>Get acquainted with Pictoblox and its AI features.
              </span>
              <span className="locontent">
                <span>L04: </span>Develop simple AI projects, including chatbots and image recognition tools.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Scratch3</li>
                <li>Pictoblox</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to AI Concepts
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                     Fundamentals of Programming with Scratch3
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Exploring Pictoblox and Simple AI Projects
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Intermediate Level (Ages 11-13)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Dive deeper into the AI realm, exploring intricate concepts and tools that power modern AI systems. Through Pictoblox Advanced and its ML extensions, students will undertake advanced projects, gaining a solid understanding of algorithms, voice recognition, and image processing.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Understand and implement advanced AI and machine learning concepts.
              </span>
              <span className="locontent">
                <span>L02: </span>Use Pictoblox's ML extensions for making predictions.
              </span>
              <span className="locontent">
                <span>L03: </span>Design intricate AI-based projects, from voice-activated assistants to image-based games.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Pictoblox Advanced</li>
                <li>ML Extensions for Pictoblox</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Deeper Dive into AI and Machine Learning
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advanced Projects in Pictoblox
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Sound and Voice Recognition in AI
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to Image Recognition
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Advanced Level (Ages 14-16)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Prepare students for the professional AI world by introducing them to Python, the backbone of modern AI and machine learning. They will not only master Python programming but also use Python libraries to design data visualizations and basic machine learning models.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Grasp Python fundamentals and its relevance in AI.
              </span>
              <span className="locontent">
                <span>L02: </span>Utilize Python libraries like NumPy, pandas, and matplotlib for data processing and visualization.
              </span>
              <span className="locontent">
                <span>L03: </span>Understand machine learning concepts and implement them using scikit-learn.
              </span>
              <span className="locontent">
                <span>L04: </span>Design and develop machine learning projects, including classifiers and predictors.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Python</li>
                <li>Libraries: NumPy, pandas, matplotlib, scikit-learn.</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to Python for AI
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Fundamentals of Python
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Data Processing and Visualization
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Basics of Machine Learning with Python: Building a Machine Learning Project
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : btn1 && isMobile && !champ && !metaverse && !gaming ?(
        <>
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 1</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Students learn to articulate their thoughts through code and
                  technology
                </li>
                <li>
                  Communicating mathematically to the computer/device via code
                </li>
                <li>Learn to get familiar with basic programming concepts</li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Learners implement and evaluate procedures for
                exploring and solving issues using mathematical knowledge,
                logic, and abilities.
              </span>
              <span className="locontent">
                <span>L02: </span>Getting familiar with basic programming
                concepts like input, output, variables, conditions and loops
              </span>
              <span className="locontent">
                <span>L03: </span>Learn to install and download multiple
                software that helps make games and animations using block
                coding.
              </span>
              <span className="locontent">
                <span>L04: </span>K-turtle interface and fundamental
                instructions are needed to accomplish many transitions.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Computational thinking</li>
                <li>Mathematical knowledge</li>
                <li>Creativity</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 2</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>Bringing ideas from conception to realisation</li>
                <li>Comprehensive skills in the Microsoft Office suite</li>
                <li>
                  Demonstrating a creative mentality (rather than a fixed
                  attitude)
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Utilising fundamental programming components
                in k-turtle regarding degrees and directions to design programs.
              </span>
              <span className="locontent">
                <span>L02: </span>Discover methods to employ MS-Word, MS-Excel
                and MS-PowerPoint.
              </span>
              <span className="locontent">
                <span>L03: </span>Examine advanced alternatives to produce
                animation and games using scratch and programming concepts.
              </span>
              <span className="locontent">
                <span>L04: </span>Simple picture editing using PIXLR.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Picture Editing tools</li>
                <li>Programming Tesselations</li>
                <li>Microsoft Office Suite</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 3</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Understand the programming fundamentals utilizing numerous
                  coding platforms.
                </li>
                <li>
                  Get an advanced degree of knowledge in video and photo
                  editing.
                </li>
                <li>
                  Discuss and produce ideas for the development and
                  implementation of solutions and projects.
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Recording and editing videos using various
                tools to build help design pictures and videos on an advanced
                level.
              </span>
              <span className="locontent">
                <span>L02: </span>Learners will study ideas like algorithms,
                machine learning, and neural networks as a fundamental
                introduction.
              </span>
              <span className="locontent">
                <span>L03: </span>Advanced components of games and apps are
                developed using block coding and programming languages.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Apps and games development</li>
                <li>Managing information and thinking</li>
                <li>Artificial Intelligence</li>
              </ul>
            </Col>
          </Row>
        </>
      ) :''}
        {/* button2 */}
        {champ ? (
          <Button
            className="innovatorbutton"
            style={{
              background: btn2 ? "#fff" : "#e5e2ec",
              borderRadius: "0",
              width: champ && !isMobile ? "33.33%" : "",
            }}
            onClick={ButtonTwoHandler}
          >
            <span>Web Development</span>
            <img
              src={arrow}
              alt=""
              className="arrow"
              style={{ transform: btn2 ? "" : "rotate(90deg)" }}
            />
            <div
              className="line"
              style={{ display: btn2 || isMobile ? "none" : "" }}
            />
          </Button>
        ) : gaming ? (
          ""
        ) : metaverse ? (
          <Button
            className="innovatorbutton"
            style={{
              background: btn2 ? "#fff" : "#e5e2ec",
              borderRadius: "0",
              width: metaverse && !isMobile ? "50%" : "",
            }}
            onClick={ButtonTwoHandler}
          >
            <span>Data Science</span>
            <img
              src={arrow}
              alt=""
              className="arrow"
              style={{ transform: btn2 ? "" : "rotate(90deg)" }}
            />
            <div
              className="line"
              style={{ display: btn2 || isMobile || metaverse ? "none" : "" }}
            />
          </Button>
        ) : (
          <Button
            className="innovatorbutton"
            style={{
              background: btn2 ? "#fff" : "#e5e2ec",
              borderRadius: "0",
            }}
            onClick={ButtonTwoHandler}
          >
            <span>Junior Web Designer</span>
            <img
              src={arrow}
              alt=""
              className="arrow"
              style={{ transform: btn2 ? "" : "rotate(90deg)" }}
            />
            <div
              className="line"
              style={{ display: btn2 || isMobile ? "none" : "" }}
            />
          </Button>
        )}

        {/* {btn2 && isMobile && (
          <>
            <Row className="innovatorow3">
              <Col className="innovatorcols">
                <Button className="levelbutton">Level 1</Button>
                <span
                  className="programhighlightstitle"
                  style={{ textAlign: "center" }}
                >
                  Program highlights
                </span>
                <ul className="phlists">
                  <li>
                    Students learn to articulate their thoughts through code and
                    technology
                  </li>
                  <li>
                    Communicating mathematically to the computer/device via code
                  </li>
                  <li>Learn to get familiar with basic programming concepts</li>
                </ul>
                <span className="programhighlightstitle">
                  Learning outcomes
                </span>
                <span className="locontent">
                  <span>L01: </span>Learners implement and evaluate procedures
                  for exploring and solving issues using mathematical knowledge,
                  logic, and abilities.
                </span>
                <span className="locontent">
                  <span>L02: </span>Getting familiar with basic programming
                  concepts like input, output, variables, conditions and loops
                </span>
                <span className="locontent">
                  <span>L03: </span>Learn to install and download multiple
                  software that helps make games and animations using block
                  coding.
                </span>
                <span className="locontent">
                  <span>L04: </span>K-turtle interface and fundamental
                  instructions are needed to accomplish many transitions.
                </span>
                <span className="programhighlightstitle">
                  Improvement areas
                </span>
                <ul className="phlists">
                  <li>Computational thinking</li>
                  <li>Mathematical knowledge</li>
                  <li>Creativity</li>
                </ul>
              </Col>
              <Col className="innovatorcols">
                <Button className="levelbutton">Level 2</Button>
                <span
                  className="programhighlightstitle"
                  style={{ textAlign: "center" }}
                >
                  Program highlights
                </span>
                <ul className="phlists">
                  <li>Bringing ideas from conception to realisation</li>
                  <li>Comprehensive skills in the Microsoft Office suite</li>
                  <li>
                    Demonstrating a creative mentality (rather than a fixed
                    attitude)
                  </li>
                </ul>
                <span className="programhighlightstitle">
                  Learning outcomes
                </span>
                <span className="locontent">
                  <span>L01: </span>Utilising fundamental programming components
                  in k-turtle regarding degrees and directions to design
                  programs.
                </span>
                <span className="locontent">
                  <span>L02: </span>Discover methods to employ MS-Word, MS-Excel
                  and MS-PowerPoint.
                </span>
                <span className="locontent">
                  <span>L03: </span>Examine advanced alternatives to produce
                  animation and games using scratch and programming concepts.
                </span>
                <span className="locontent">
                  <span>L04: </span>Simple picture editing using PIXLR.
                </span>
                <span className="programhighlightstitle">
                  Improvement areas
                </span>
                <ul className="phlists">
                  <li>Picture Editing tools</li>
                  <li>Programming Tesselations</li>
                  <li>Microsoft Office Suite</li>
                </ul>
              </Col>
              <Col className="innovatorcols">
                <Button className="levelbutton">Level 3</Button>
                <span
                  className="programhighlightstitle"
                  style={{ textAlign: "center" }}
                >
                  Program highlights
                </span>
                <ul className="phlists">
                  <li>
                    Understand the programming fundamentals utilizing numerous
                    coding platforms.
                  </li>
                  <li>
                    Get an advanced degree of knowledge in video and photo
                    editing.
                  </li>
                  <li>
                    Discuss and produce ideas for the development and
                    implementation of solutions and projects.
                  </li>
                </ul>
                <span className="programhighlightstitle">
                  Learning outcomes
                </span>
                <span className="locontent">
                  <span>L01: </span>Recording and editing videos using various
                  tools to build help design pictures and videos on an advanced
                  level.
                </span>
                <span className="locontent">
                  <span>L02: </span>Learners will study ideas like algorithms,
                  machine learning, and neural networks as a fundamental
                  introduction.
                </span>
                <span className="locontent">
                  <span>L03: </span>Advanced components of games and apps are
                  developed using block coding and programming languages.
                </span>
                <span className="programhighlightstitle">
                  Improvement areas
                </span>
                <ul className="phlists">
                  <li>Apps and games development</li>
                  <li>Managing information and thinking</li>
                  <li>Artificial Intelligence</li>
                </ul>
              </Col>
            </Row>
          </>
        )} */}
        {btn2 && isMobile && champ ? (
        <>
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
                Base Level (Ages 8-10):
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
                This level introduces young learners to the foundational aspects
                of web development. Participants will understand how websites
                function, the roles of web developers, and the basics of HTML &
                CSS. By the end, they will have hands-on experience in building
                a simple website.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Comprehend the fundamentals of the Internet,
                website structures, and web developer roles
              </span>
              <span className="locontent">
                <span>L02: </span>Build basic web pages using Google Sites with
                design and layout principles.
              </span>
              <span className="locontent">
                <span>L03: </span>Acquire skills in using HTML for site
                structure and CSS for styling.
              </span>
              <span className="locontent">
                <span>L04: </span>Successfully conceptualize, design, and launch
                a basic website.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Google Sites</li>
                <li>HTML</li>
                <li>CSS</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Web Development Introduction
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Getting Started with Google Sites
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to HTML & CSS
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Building a Basic Website
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Intermediate Level (Ages 11-13)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
                The intermediate stage delves deeper into advanced features of
                HTML & CSS, and introduces Bootstrap and JavaScript. Learners
                will get hands-on experience in building responsive web designs
                and adding interactivity to web pages.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Master advanced HTML & CSS techniques,
                including semantic HTML and CSS Flexbox/Grid.
              </span>
              <span className="locontent">
                <span>L02: </span>Utilize Bootstrap for responsive design and
                component-based structures.
              </span>
              <span className="locontent">
                <span>L03: </span>Understand and apply fundamental JavaScript
                concepts, especially for interactivity.
              </span>
              <span className="locontent">
                <span>L04: </span>Construct a responsive webpage that's
                interactive and user-centric.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>HTML</li>
                <li>CSS</li>
                <li>Bootstrap</li>
                <li>JavaScript</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Deep Dive into HTML & CSS
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to Bootstrap
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to JavaScript
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Building a Responsive Web Page
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Advanced Level (Ages 14-16)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              In the advanced tier, participants will be equipped with sophisticated JavaScript techniques, delve into jQuery for efficient DOM manipulations, and integrate JSON for data handling. They'll also explore the Geolocation API to create location-aware web applications.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Dive deep into advanced JavaScript, including ES6 features and AJAX basics.
              </span>
              <span className="locontent">
                <span>L02: </span>Utilize jQuery for enhanced DOM manipulations and animations.
              </span>
              <span className="locontent">
                <span>L03: </span>Understand JSON and its applications in fetching, processing, and storing data.
              </span>
              <span className="locontent">
                <span>L04: </span>Develop location-aware web applications using the Geolocation API.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>JavaScript (JS)</li>
                <li>jQuery</li>
                <li>JSON</li>
                <li>Geolocation APIs</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advanced JavaScript Techniques
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Exploring jQuery
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Working with JSON and Data
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Implementing Geolocation APIs
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : btn2 && isMobile && metaverse ? (
        <>
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
                Base Level (Ages 8-10):
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Dive into the foundational concepts of data science tailored for young learners. Through this course, students will uncover what data is, explore basic statistical concepts, and leverage platforms like Scratch for data visualization and storytelling.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Understand the differences between qualitative and quantitative data.
              </span>
              <span className="locontent">
                <span>L02: </span>Recognize various data collection methods.
              </span>
              <span className="locontent">
                <span>L03: </span>Visualize and interpret data using basic statistical measures.
              </span>
              <span className="locontent">
                <span>L04: </span>Delve into data storytelling and ethical considerations.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Google Sheets</li>
                <li>Scratch</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Understanding and Collecting Data
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Visualization and Basic Statistical Concepts
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to Coding with Scratch
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Storytelling with Data and Ethics
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Intermediate Level (Ages 11-13)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Level up in the data science journey by mastering Python's core concepts, libraries, and methodologies. Learners will harness Python's power for data processing, visualization, and delve into basic machine learning projects, all while emphasizing ethical considerations.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Master Python fundamentals, data structures, and libraries.
              </span>
              <span className="locontent">
                <span>L02: </span>Analyze and visualize datasets using pandas, NumPy, and matplotlib.
              </span>
              <span className="locontent">
                <span>L03: </span>Implement basic machine learning projects.
              </span>
              <span className="locontent">
                <span>L04: </span>Understand ethical aspects of data and machine learning.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Python</li>
                <li>Libraries: NumPy, pandas, matplotlib</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to Python for AI
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Fundamentals of Python
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Data Processing and Visualization
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Practical Applications and Ethics in Data
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Advanced Level (Ages 14-16)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              This advanced module is designed to prepare students for professional data science roles. Deepening their Python knowledge, they'll engage with data modeling, machine learning techniques, and gain insights into big data technologies, ethics, and career opportunities in the field.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Dive deeper into Python programming and its libraries.
              </span>
              <span className="locontent">
                <span>L02: </span>Understand and implement advanced data modeling techniques.
              </span>
              <span className="locontent">
                <span>L03: </span>Undertake hands-on machine learning projects on real-world datasets.
              </span>
              <span className="locontent">
                <span>L04: </span>Grasp the essentials of big data technologies and their applications.
              </span>
              <span className="locontent">
                <span>L05: </span>Engage in deep discussions about data ethics, bias, and societal implications.
              </span>
              <span className="locontent">
                <span>L06: </span>Explore potential career pathways in data science.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Python</li>
                <li>Libraries: scikit-learn, Hadoop, Spark.</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advanced Programming and Libraries
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Deep Dive into Data Modeling
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Real-world Machine Learning Projects
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Big Data, Ethics, and Career Pathways
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : btn2 && isMobile && !champ && !metaverse? (
        <>
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 1</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Students learn to articulate their thoughts through code and
                  technology
                </li>
                <li>
                  Communicating mathematically to the computer/device via code
                </li>
                <li>Learn to get familiar with basic programming concepts</li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Learners implement and evaluate procedures for
                exploring and solving issues using mathematical knowledge,
                logic, and abilities.
              </span>
              <span className="locontent">
                <span>L02: </span>Getting familiar with basic programming
                concepts like input, output, variables, conditions and loops
              </span>
              <span className="locontent">
                <span>L03: </span>Learn to install and download multiple
                software that helps make games and animations using block
                coding.
              </span>
              <span className="locontent">
                <span>L04: </span>K-turtle interface and fundamental
                instructions are needed to accomplish many transitions.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Computational thinking</li>
                <li>Mathematical knowledge</li>
                <li>Creativity</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 2</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>Bringing ideas from conception to realisation</li>
                <li>Comprehensive skills in the Microsoft Office suite</li>
                <li>
                  Demonstrating a creative mentality (rather than a fixed
                  attitude)
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Utilising fundamental programming components
                in k-turtle regarding degrees and directions to design programs.
              </span>
              <span className="locontent">
                <span>L02: </span>Discover methods to employ MS-Word, MS-Excel
                and MS-PowerPoint.
              </span>
              <span className="locontent">
                <span>L03: </span>Examine advanced alternatives to produce
                animation and games using scratch and programming concepts.
              </span>
              <span className="locontent">
                <span>L04: </span>Simple picture editing using PIXLR.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Picture Editing tools</li>
                <li>Programming Tesselations</li>
                <li>Microsoft Office Suite</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 3</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Understand the programming fundamentals utilizing numerous
                  coding platforms.
                </li>
                <li>
                  Get an advanced degree of knowledge in video and photo
                  editing.
                </li>
                <li>
                  Discuss and produce ideas for the development and
                  implementation of solutions and projects.
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Recording and editing videos using various
                tools to build help design pictures and videos on an advanced
                level.
              </span>
              <span className="locontent">
                <span>L02: </span>Learners will study ideas like algorithms,
                machine learning, and neural networks as a fundamental
                introduction.
              </span>
              <span className="locontent">
                <span>L03: </span>Advanced components of games and apps are
                developed using block coding and programming languages.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Apps and games development</li>
                <li>Managing information and thinking</li>
                <li>Artificial Intelligence</li>
              </ul>
            </Col>
          </Row>
        </>
      ) : ''}
        {/* button3 */}
        {champ ? (
          <Button
            className="innovatorbutton"
            style={{
              background: btn3 ? "#fff" : "#e5e2ec",
              borderRadius: "0",
              width: champ && !isMobile ? "33.33%" : "",
            }}
            onClick={ButtonThreeHandler}
          >
            <span>Application Development</span>
            <img
              src={arrow}
              alt=""
              className="arrow"
              style={{ transform: btn3 ? "" : "rotate(90deg)" }}
            />
            <div
              className="line"
              style={{ display: btn3 || isMobile || champ ? "none" : "" }}
            />
          </Button>
        ) : gaming ? (
          ""
        ) : metaverse ? (
          ""
        ) : (
          <Button
            className="innovatorbutton"
            style={{
              background: btn3 ? "#fff" : "#e5e2ec",
              borderRadius: "0",
            }}
            onClick={ButtonThreeHandler}
          >
            <span>Python Programming</span>
            <img
              src={arrow}
              alt=""
              className="arrow"
              style={{ transform: btn3 ? "" : "rotate(90deg)" }}
            />
            <div
              className="line"
              style={{ display: btn3 || isMobile ? "none" : "" }}
            />
          </Button>
        )}

        {/* {btn3 && isMobile && (
          <>
            <Row className="innovatorow3">
              <Col className="innovatorcols">
                <Button className="levelbutton">Level 1</Button>
                <span
                  className="programhighlightstitle"
                  style={{ textAlign: "center" }}
                >
                  Program highlights
                </span>
                <ul className="phlists">
                  <li>
                    Students learn to articulate their thoughts through code and
                    technology
                  </li>
                  <li>
                    Communicating mathematically to the computer/device via code
                  </li>
                  <li>Learn to get familiar with basic programming concepts</li>
                </ul>
                <span className="programhighlightstitle">
                  Learning outcomes
                </span>
                <span className="locontent">
                  <span>L01: </span>Learners implement and evaluate procedures
                  for exploring and solving issues using mathematical knowledge,
                  logic, and abilities.
                </span>
                <span className="locontent">
                  <span>L02: </span>Getting familiar with basic programming
                  concepts like input, output, variables, conditions and loops
                </span>
                <span className="locontent">
                  <span>L03: </span>Learn to install and download multiple
                  software that helps make games and animations using block
                  coding.
                </span>
                <span className="locontent">
                  <span>L04: </span>K-turtle interface and fundamental
                  instructions are needed to accomplish many transitions.
                </span>
                <span className="programhighlightstitle">
                  Improvement areas
                </span>
                <ul className="phlists">
                  <li>Computational thinking</li>
                  <li>Mathematical knowledge</li>
                  <li>Creativity</li>
                </ul>
              </Col>
              <Col className="innovatorcols">
                <Button className="levelbutton">Level 2</Button>
                <span
                  className="programhighlightstitle"
                  style={{ textAlign: "center" }}
                >
                  Program highlights
                </span>
                <ul className="phlists">
                  <li>Bringing ideas from conception to realisation</li>
                  <li>Comprehensive skills in the Microsoft Office suite</li>
                  <li>
                    Demonstrating a creative mentality (rather than a fixed
                    attitude)
                  </li>
                </ul>
                <span className="programhighlightstitle">
                  Learning outcomes
                </span>
                <span className="locontent">
                  <span>L01: </span>Utilising fundamental programming components
                  in k-turtle regarding degrees and directions to design
                  programs.
                </span>
                <span className="locontent">
                  <span>L02: </span>Discover methods to employ MS-Word, MS-Excel
                  and MS-PowerPoint.
                </span>
                <span className="locontent">
                  <span>L03: </span>Examine advanced alternatives to produce
                  animation and games using scratch and programming concepts.
                </span>
                <span className="locontent">
                  <span>L04: </span>Simple picture editing using PIXLR.
                </span>
                <span className="programhighlightstitle">
                  Improvement areas
                </span>
                <ul className="phlists">
                  <li>Picture Editing tools</li>
                  <li>Programming Tesselations</li>
                  <li>Microsoft Office Suite</li>
                </ul>
              </Col>
              <Col className="innovatorcols">
                <Button className="levelbutton">Level 3</Button>
                <span
                  className="programhighlightstitle"
                  style={{ textAlign: "center" }}
                >
                  Program highlights
                </span>
                <ul className="phlists">
                  <li>
                    Understand the programming fundamentals utilizing numerous
                    coding platforms.
                  </li>
                  <li>
                    Get an advanced degree of knowledge in video and photo
                    editing.
                  </li>
                  <li>
                    Discuss and produce ideas for the development and
                    implementation of solutions and projects.
                  </li>
                </ul>
                <span className="programhighlightstitle">
                  Learning outcomes
                </span>
                <span className="locontent">
                  <span>L01: </span>Recording and editing videos using various
                  tools to build help design pictures and videos on an advanced
                  level.
                </span>
                <span className="locontent">
                  <span>L02: </span>Learners will study ideas like algorithms,
                  machine learning, and neural networks as a fundamental
                  introduction.
                </span>
                <span className="locontent">
                  <span>L03: </span>Advanced components of games and apps are
                  developed using block coding and programming languages.
                </span>
                <span className="programhighlightstitle">
                  Improvement areas
                </span>
                <ul className="phlists">
                  <li>Apps and games development</li>
                  <li>Managing information and thinking</li>
                  <li>Artificial Intelligence</li>
                </ul>
              </Col>
            </Row>
          </>
        )} */}
        {btn3 && isMobile && champ ? (
        <>
        {/* application developement */}
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
                Base Level (Ages 8-10):
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Young learners are introduced to the world of app development through hands-on experiences with Scratch3 and App Lab. They'll design interactive stories, quiz games, and delve into the basics of app creation.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Recognize different types of apps and understand their significance.
              </span>
              <span className="locontent">
                <span>L02: </span>Design interactive projects with Scratch3 incorporating basic user inputs, scoring, and sounds.
              </span>
              <span className="locontent">
                <span>L03: </span>Grasp the basic interface and scripting fundamentals of App Lab.
              </span>
              <span className="locontent">
                <span>L04: </span>Successfully design and present a basic app.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Scratch3</li>
                <li>App Lab</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to App Development
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Basic App Projects with Scratch3: Animations and Transitions
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Transition to App Lab
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                     Concluding Projects and Presentation
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Intermediate Level (Ages 11-13)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Building upon the foundation, learners dive deeper into advanced app concepts, interface design, and integrating JavaScript. The focus is on advanced features, scripting, and refining app designs.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Comprehend advanced app logic, event handling, and interface design.
              </span>
              <span className="locontent">
                <span>L02: </span>Learn JavaScript fundamentals and integrate them within App Lab.
              </span>
              <span className="locontent">
                <span>L03: </span>Construct advanced projects in App Lab incorporating game mechanics or tool functionalities.
              </span>
              <span className="locontent">
                <span>L04: </span>Design, refine, and present an app integrated with JavaScript.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>App Lab</li>
                <li>JavaScript</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advancing App Development Concepts
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to JavaScript for Apps
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advanced App Lab Projects
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Concluding Projects with JavaScript and App Lab
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Advanced Level (Ages 14-16)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              At the advanced level, learners explore the realm of App Inventor, delving into advanced features, databases, and external APIs. They design complex apps with multiple features, screens, and ensure seamless user experience.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Familiarize with App Inventor's interface, design aspects, and coding blocks.
              </span>
              <span className="locontent">
                <span>L02: </span>Design apps integrating advanced features such as databases and location services.
              </span>
              <span className="locontent">
                <span>L03: </span>Construct apps with multiple screens, integrating external APIs.
              </span>
              <span className="locontent">
                <span>L04: </span>Conceive, design, and present a comprehensive app project.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>App Inventor</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to App Inventor
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                     Delving Deeper into App Features
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advanced App Creation
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Final App Projects and Presentation
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : btn3 && isMobile && !champ ? (
        <>
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 1</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Students learn to articulate their thoughts through code and
                  technology
                </li>
                <li>
                  Communicating mathematically to the computer/device via code
                </li>
                <li>Learn to get familiar with basic programming concepts</li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Learners implement and evaluate procedures for
                exploring and solving issues using mathematical knowledge,
                logic, and abilities.
              </span>
              <span className="locontent">
                <span>L02: </span>Getting familiar with basic programming
                concepts like input, output, variables, conditions and loops
              </span>
              <span className="locontent">
                <span>L03: </span>Learn to install and download multiple
                software that helps make games and animations using block
                coding.
              </span>
              <span className="locontent">
                <span>L04: </span>K-turtle interface and fundamental
                instructions are needed to accomplish many transitions.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Computational thinking</li>
                <li>Mathematical knowledge</li>
                <li>Creativity</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 2</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>Bringing ideas from conception to realisation</li>
                <li>Comprehensive skills in the Microsoft Office suite</li>
                <li>
                  Demonstrating a creative mentality (rather than a fixed
                  attitude)
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Utilising fundamental programming components
                in k-turtle regarding degrees and directions to design programs.
              </span>
              <span className="locontent">
                <span>L02: </span>Discover methods to employ MS-Word, MS-Excel
                and MS-PowerPoint.
              </span>
              <span className="locontent">
                <span>L03: </span>Examine advanced alternatives to produce
                animation and games using scratch and programming concepts.
              </span>
              <span className="locontent">
                <span>L04: </span>Simple picture editing using PIXLR.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Picture Editing tools</li>
                <li>Programming Tesselations</li>
                <li>Microsoft Office Suite</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 3</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Understand the programming fundamentals utilizing numerous
                  coding platforms.
                </li>
                <li>
                  Get an advanced degree of knowledge in video and photo
                  editing.
                </li>
                <li>
                  Discuss and produce ideas for the development and
                  implementation of solutions and projects.
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Recording and editing videos using various
                tools to build help design pictures and videos on an advanced
                level.
              </span>
              <span className="locontent">
                <span>L02: </span>Learners will study ideas like algorithms,
                machine learning, and neural networks as a fundamental
                introduction.
              </span>
              <span className="locontent">
                <span>L03: </span>Advanced components of games and apps are
                developed using block coding and programming languages.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Apps and games development</li>
                <li>Managing information and thinking</li>
                <li>Artificial Intelligence</li>
              </ul>
            </Col>
          </Row>
        </>
      ) : ''}
        {/* button4 */}
        {!champ && !gaming && !metaverse && (
          <Button
            className="innovatorbutton"
            style={{
              background: btn4 ? "#fff" : "#e5e2ec",
              borderRadius: "0",
            }}
            onClick={ButtonFourHandler}
          >
            <span>JavaScript Development</span>
            <img
              src={arrow}
              alt=""
              className="arrow"
              style={{ transform: btn4 ? "" : "rotate(90deg)" }}
            />
          </Button>
        )}
        {btn4 && isMobile && (
          <>
            <Row className="innovatorow3">
              <Col className="innovatorcols">
                <Button className="levelbutton">Level 1</Button>
                <span
                  className="programhighlightstitle"
                  style={{ textAlign: "center" }}
                >
                  Program highlights
                </span>
                <ul className="phlists">
                  <li>
                    Students learn to articulate their thoughts through code and
                    technology
                  </li>
                  <li>
                    Communicating mathematically to the computer/device via code
                  </li>
                  <li>Learn to get familiar with basic programming concepts</li>
                </ul>
                <span className="programhighlightstitle">
                  Learning outcomes
                </span>
                <span className="locontent">
                  <span>L01: </span>Learners implement and evaluate procedures
                  for exploring and solving issues using mathematical knowledge,
                  logic, and abilities.
                </span>
                <span className="locontent">
                  <span>L02: </span>Getting familiar with basic programming
                  concepts like input, output, variables, conditions and loops
                </span>
                <span className="locontent">
                  <span>L03: </span>Learn to install and download multiple
                  software that helps make games and animations using block
                  coding.
                </span>
                <span className="locontent">
                  <span>L04: </span>K-turtle interface and fundamental
                  instructions are needed to accomplish many transitions.
                </span>
                <span className="programhighlightstitle">
                  Improvement areas
                </span>
                <ul className="phlists">
                  <li>Computational thinking</li>
                  <li>Mathematical knowledge</li>
                  <li>Creativity</li>
                </ul>
              </Col>
              <Col className="innovatorcols">
                <Button className="levelbutton">Level 2</Button>
                <span
                  className="programhighlightstitle"
                  style={{ textAlign: "center" }}
                >
                  Program highlights
                </span>
                <ul className="phlists">
                  <li>Bringing ideas from conception to realisation</li>
                  <li>Comprehensive skills in the Microsoft Office suite</li>
                  <li>
                    Demonstrating a creative mentality (rather than a fixed
                    attitude)
                  </li>
                </ul>
                <span className="programhighlightstitle">
                  Learning outcomes
                </span>
                <span className="locontent">
                  <span>L01: </span>Utilising fundamental programming components
                  in k-turtle regarding degrees and directions to design
                  programs.
                </span>
                <span className="locontent">
                  <span>L02: </span>Discover methods to employ MS-Word, MS-Excel
                  and MS-PowerPoint.
                </span>
                <span className="locontent">
                  <span>L03: </span>Examine advanced alternatives to produce
                  animation and games using scratch and programming concepts.
                </span>
                <span className="locontent">
                  <span>L04: </span>Simple picture editing using PIXLR.
                </span>
                <span className="programhighlightstitle">
                  Improvement areas
                </span>
                <ul className="phlists">
                  <li>Picture Editing tools</li>
                  <li>Programming Tesselations</li>
                  <li>Microsoft Office Suite</li>
                </ul>
              </Col>
              <Col className="innovatorcols">
                <Button className="levelbutton">Level 3</Button>
                <span
                  className="programhighlightstitle"
                  style={{ textAlign: "center" }}
                >
                  Program highlights
                </span>
                <ul className="phlists">
                  <li>
                    Understand the programming fundamentals utilizing numerous
                    coding platforms.
                  </li>
                  <li>
                    Get an advanced degree of knowledge in video and photo
                    editing.
                  </li>
                  <li>
                    Discuss and produce ideas for the development and
                    implementation of solutions and projects.
                  </li>
                </ul>
                <span className="programhighlightstitle">
                  Learning outcomes
                </span>
                <span className="locontent">
                  <span>L01: </span>Recording and editing videos using various
                  tools to build help design pictures and videos on an advanced
                  level.
                </span>
                <span className="locontent">
                  <span>L02: </span>Learners will study ideas like algorithms,
                  machine learning, and neural networks as a fundamental
                  introduction.
                </span>
                <span className="locontent">
                  <span>L03: </span>Advanced components of games and apps are
                  developed using block coding and programming languages.
                </span>
                <span className="programhighlightstitle">
                  Improvement areas
                </span>
                <ul className="phlists">
                  <li>Apps and games development</li>
                  <li>Managing information and thinking</li>
                  <li>Artificial Intelligence</li>
                </ul>
              </Col>
            </Row>
          </>
        )}
      </Row>

      {/* web */}
      {btn1 && !isMobile && champ ? (
        <>
          <Row className="innovatorow3">
            <div className="basicprgrm">
              <span className="coursedesctitle">Course Description: </span>
              <br />
              <span className="coursedesccontent">
                Basic Programming is a comprehensive foundational program that
                introduces young learners to the world of programming and
                digital technology. Over 16 hours, participants will embark on a
                journey through interactive platforms such as ScratchJr and
                Tynker, while also gaining a basic understanding of data through
                Google Sheets and website design via Google Sites. The course is
                designed to harness kids' natural curiosity and foster
                creativity, ensuring that learning remains an engaging and
                enjoyable experience. Through a series of structured modules,
                children will create animations, understand data, and even
                launch their personal webpages, paving the way for further
                exploration in the digital realm.
              </span>
              <br />
              <span
                className="coursedesctitle"
                style={{ marginBottom: "10px" }}
              >
                Learning outcomes
              </span>
              <span
                className="coursedesccontent"
                style={{ marginBottom: "7px" }}
              >
                <span className="coursedesctitle" style={{ fontSize: "18px" }}>
                  L01 :{" "}
                </span>
                Students will master foundational programming using ScratchJr
                and Tynker.
              </span>
              <span
                className="coursedesccontent"
                style={{ marginBottom: "7px" }}
              >
                <span className="coursedesctitle" style={{ fontSize: "18px" }}>
                  L02 :{" "}
                </span>
                Learners will become proficient with important digital tools
                like keyboards, mouse, and Google Sheets.
              </span>
              <span
                className="coursedesccontent"
                style={{ marginBottom: "7px" }}
              >
                <span className="coursedesctitle" style={{ fontSize: "18px" }}>
                  L03 :{" "}
                </span>
                Participants will construct personal webpages using Google
                Sites, knowing key design principles.
              </span>
              <span
                className="coursedesccontent"
                style={{ marginBottom: "30px" }}
              >
                <span className="coursedesctitle" style={{ fontSize: "18px" }}>
                  L04 :{" "}
                </span>
                Children will cultivate collaborative skills and reflective
                practices via project sharing and feedback.
              </span>
              <span className="coursedesctitle">Tools Used:</span>
              <br />
              <ul
                className="coursedesccontent"
                style={{ marginBottom: "3rem" }}
              >
                <li>ScratchJr</li>
                <li>Keyboard and Mouse</li>
                <li>Tynker</li>
                <li>Google Sheets</li>
                <li>Google Sites</li>
                <li>Scratch3 (introductory)</li>
              </ul>
              <span className="coursedesctitle">Course Modules: </span>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td className="coursedesccontent">
                      Introduction to Programming Concepts: ScratchJr
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td className="coursedesccontent">
                      Exploring Keyboard and Mouse
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td className="coursedesccontent">
                      Data and Simple Calculations: Google Sheets
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td className="coursedesccontent">
                      Building and Sharing Our Work: Google Sites & Scratch3
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Row>
        </>
      ) : btn1 && !isMobile && gaming ?(
        <>
        {/* Game developement */}
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
                Base Level (Ages 8-10):
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              This base level aims to introduce young learners to the fascinating world of game development. Through engaging platforms like Scratch3 and Construct, students will create their own simple games, understanding the core concepts behind game design and mechanics.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Understand different types of games and their significance in the digital landscape.
              </span>
              <span className="locontent">
                <span>L02: </span>Familiarize with the Scratch3 and Construct interfaces and tools.
              </span>
              <span className="locontent">
                <span>L03: </span>Develop basic games on Scratch3, including mechanics like scoring and levels.
              </span>
              <span className="locontent">
                <span>L04: </span>Transition to Construct to design a simple platformer game.
              </span>
              <span className="locontent">
                <span>L05: </span>Showcase a game of their choice, applying learned concepts.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Scratch3</li>
                <li>Construct</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to Game Development
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                     Basic Game Mechanics with Scratch3
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Moving to Construct
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                     Advanced Concepts and Final Project
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Intermediate Level (Ages 11-13)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Diving deeper into the game development realm, this intermediate level exposes students to the scientific concepts behind game creation. They'll work with Game Lab and delve into C# programming, which forms the backbone of many contemporary games.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Comprehend advanced game development concepts like game loops, events, and input handling.
              </span>
              <span className="locontent">
                <span>L02: </span>Gain proficiency in Game Lab's interface and scripting.
              </span>
              <span className="locontent">
                <span>L03: </span>Grasp C# fundamentals and its application in game logic and mechanics.
              </span>
              <span className="locontent">
                <span>L04: </span>Understand object-oriented programming and its relevance in gaming.
              </span>
              <span className="locontent">
                <span>L05: </span>Design and present a game project, applying advanced mechanics and logic.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Game Lab</li>
                <li>C# programming language</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Deep Dive into Game Concepts
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Programming Basics with C#
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advanced Game Lab Development
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Wrapping Up with C# and Game Lab
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Advanced Level (Ages 14-16)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Designed for seasoned learners, the advanced level dives into professional game development using Unity3D. Students will explore 3D game development, script their own game mechanics, and implement sophisticated design principles to craft a compelling gaming experience.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Navigate the Unity3D interface, understanding essential components like scenes, game objects, and assets.
              </span>
              <span className="locontent">
                <span>L02: </span>Delve into 3D game development basics, from camera placement to importing 3D models.
              </span>
              <span className="locontent">
                <span>L03: </span>Master Unity's scripting using C#, manipulating game objects and interactions.
              </span>
              <span className="locontent">
                <span>L04: </span>Implement animation, sound, and user experience principles in game design.
              </span>
              <span className="locontent">
                <span>L05: </span>Conceptualize, design, and present a comprehensive Unity3D game project.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Unity3D</li>
                <li>C# programming language within the Unity environment.</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to Unity3D
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                     Scripting in Unity with C#
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advanced Unity Features
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Final Unity Projects
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : btn1 && !isMobile && metaverse ? (
        <>
        {/* metaverse */}
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
                Base Level (Ages 8-10):
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Introduce young learners to the intriguing world of artificial intelligence (AI) through platforms tailored for beginners. In this level, students will discover basic AI concepts and employ Scratch3 and Pictoblox to design simple AI-based projects.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Recognize what AI is and its applications in the real world.
              </span>
              <span className="locontent">
                <span>L02: </span>Understand basic programming concepts using Scratch3.
              </span>
              <span className="locontent">
                <span>L03: </span>Get acquainted with Pictoblox and its AI features.
              </span>
              <span className="locontent">
                <span>L04: </span>Develop simple AI projects, including chatbots and image recognition tools.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Scratch3</li>
                <li>Pictoblox</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to AI Concepts
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                     Fundamentals of Programming with Scratch3
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Exploring Pictoblox and Simple AI Projects
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Intermediate Level (Ages 11-13)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Dive deeper into the AI realm, exploring intricate concepts and tools that power modern AI systems. Through Pictoblox Advanced and its ML extensions, students will undertake advanced projects, gaining a solid understanding of algorithms, voice recognition, and image processing.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Understand and implement advanced AI and machine learning concepts.
              </span>
              <span className="locontent">
                <span>L02: </span>Use Pictoblox's ML extensions for making predictions.
              </span>
              <span className="locontent">
                <span>L03: </span>Design intricate AI-based projects, from voice-activated assistants to image-based games.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Pictoblox Advanced</li>
                <li>ML Extensions for Pictoblox</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Deeper Dive into AI and Machine Learning
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advanced Projects in Pictoblox
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Sound and Voice Recognition in AI
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to Image Recognition
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Advanced Level (Ages 14-16)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Prepare students for the professional AI world by introducing them to Python, the backbone of modern AI and machine learning. They will not only master Python programming but also use Python libraries to design data visualizations and basic machine learning models.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Grasp Python fundamentals and its relevance in AI.
              </span>
              <span className="locontent">
                <span>L02: </span>Utilize Python libraries like NumPy, pandas, and matplotlib for data processing and visualization.
              </span>
              <span className="locontent">
                <span>L03: </span>Understand machine learning concepts and implement them using scikit-learn.
              </span>
              <span className="locontent">
                <span>L04: </span>Design and develop machine learning projects, including classifiers and predictors.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Python</li>
                <li>Libraries: NumPy, pandas, matplotlib, scikit-learn.</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to Python for AI
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Fundamentals of Python
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Data Processing and Visualization
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Basics of Machine Learning with Python: Building a Machine Learning Project
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : btn1 && !isMobile && !champ && !metaverse && !gaming ?(
        <>
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 1</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Students learn to articulate their thoughts through code and
                  technology
                </li>
                <li>
                  Communicating mathematically to the computer/device via code
                </li>
                <li>Learn to get familiar with basic programming concepts</li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Learners implement and evaluate procedures for
                exploring and solving issues using mathematical knowledge,
                logic, and abilities.
              </span>
              <span className="locontent">
                <span>L02: </span>Getting familiar with basic programming
                concepts like input, output, variables, conditions and loops
              </span>
              <span className="locontent">
                <span>L03: </span>Learn to install and download multiple
                software that helps make games and animations using block
                coding.
              </span>
              <span className="locontent">
                <span>L04: </span>K-turtle interface and fundamental
                instructions are needed to accomplish many transitions.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Computational thinking</li>
                <li>Mathematical knowledge</li>
                <li>Creativity</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 2</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>Bringing ideas from conception to realisation</li>
                <li>Comprehensive skills in the Microsoft Office suite</li>
                <li>
                  Demonstrating a creative mentality (rather than a fixed
                  attitude)
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Utilising fundamental programming components
                in k-turtle regarding degrees and directions to design programs.
              </span>
              <span className="locontent">
                <span>L02: </span>Discover methods to employ MS-Word, MS-Excel
                and MS-PowerPoint.
              </span>
              <span className="locontent">
                <span>L03: </span>Examine advanced alternatives to produce
                animation and games using scratch and programming concepts.
              </span>
              <span className="locontent">
                <span>L04: </span>Simple picture editing using PIXLR.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Picture Editing tools</li>
                <li>Programming Tesselations</li>
                <li>Microsoft Office Suite</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 3</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Understand the programming fundamentals utilizing numerous
                  coding platforms.
                </li>
                <li>
                  Get an advanced degree of knowledge in video and photo
                  editing.
                </li>
                <li>
                  Discuss and produce ideas for the development and
                  implementation of solutions and projects.
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Recording and editing videos using various
                tools to build help design pictures and videos on an advanced
                level.
              </span>
              <span className="locontent">
                <span>L02: </span>Learners will study ideas like algorithms,
                machine learning, and neural networks as a fundamental
                introduction.
              </span>
              <span className="locontent">
                <span>L03: </span>Advanced components of games and apps are
                developed using block coding and programming languages.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Apps and games development</li>
                <li>Managing information and thinking</li>
                <li>Artificial Intelligence</li>
              </ul>
            </Col>
          </Row>
        </>
      ) :''}
      {/* button2 */}
      {btn2 && !isMobile && champ ? (
        <>
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
                Base Level (Ages 8-10):
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
                This level introduces young learners to the foundational aspects
                of web development. Participants will understand how websites
                function, the roles of web developers, and the basics of HTML &
                CSS. By the end, they will have hands-on experience in building
                a simple website.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Comprehend the fundamentals of the Internet,
                website structures, and web developer roles
              </span>
              <span className="locontent">
                <span>L02: </span>Build basic web pages using Google Sites with
                design and layout principles.
              </span>
              <span className="locontent">
                <span>L03: </span>Acquire skills in using HTML for site
                structure and CSS for styling.
              </span>
              <span className="locontent">
                <span>L04: </span>Successfully conceptualize, design, and launch
                a basic website.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Google Sites</li>
                <li>HTML</li>
                <li>CSS</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Web Development Introduction
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Getting Started with Google Sites
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to HTML & CSS
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Building a Basic Website
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Intermediate Level (Ages 11-13)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
                The intermediate stage delves deeper into advanced features of
                HTML & CSS, and introduces Bootstrap and JavaScript. Learners
                will get hands-on experience in building responsive web designs
                and adding interactivity to web pages.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Master advanced HTML & CSS techniques,
                including semantic HTML and CSS Flexbox/Grid.
              </span>
              <span className="locontent">
                <span>L02: </span>Utilize Bootstrap for responsive design and
                component-based structures.
              </span>
              <span className="locontent">
                <span>L03: </span>Understand and apply fundamental JavaScript
                concepts, especially for interactivity.
              </span>
              <span className="locontent">
                <span>L04: </span>Construct a responsive webpage that's
                interactive and user-centric.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>HTML</li>
                <li>CSS</li>
                <li>Bootstrap</li>
                <li>JavaScript</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Deep Dive into HTML & CSS
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to Bootstrap
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to JavaScript
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Building a Responsive Web Page
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Advanced Level (Ages 14-16)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              In the advanced tier, participants will be equipped with sophisticated JavaScript techniques, delve into jQuery for efficient DOM manipulations, and integrate JSON for data handling. They'll also explore the Geolocation API to create location-aware web applications.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Dive deep into advanced JavaScript, including ES6 features and AJAX basics.
              </span>
              <span className="locontent">
                <span>L02: </span>Utilize jQuery for enhanced DOM manipulations and animations.
              </span>
              <span className="locontent">
                <span>L03: </span>Understand JSON and its applications in fetching, processing, and storing data.
              </span>
              <span className="locontent">
                <span>L04: </span>Develop location-aware web applications using the Geolocation API.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>JavaScript (JS)</li>
                <li>jQuery</li>
                <li>JSON</li>
                <li>Geolocation APIs</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advanced JavaScript Techniques
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Exploring jQuery
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Working with JSON and Data
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Implementing Geolocation APIs
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : btn2 && !isMobile && metaverse ? (
        <>
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
                Base Level (Ages 8-10):
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Dive into the foundational concepts of data science tailored for young learners. Through this course, students will uncover what data is, explore basic statistical concepts, and leverage platforms like Scratch for data visualization and storytelling.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Understand the differences between qualitative and quantitative data.
              </span>
              <span className="locontent">
                <span>L02: </span>Recognize various data collection methods.
              </span>
              <span className="locontent">
                <span>L03: </span>Visualize and interpret data using basic statistical measures.
              </span>
              <span className="locontent">
                <span>L04: </span>Delve into data storytelling and ethical considerations.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Google Sheets</li>
                <li>Scratch</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Understanding and Collecting Data
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Visualization and Basic Statistical Concepts
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to Coding with Scratch
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Storytelling with Data and Ethics
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Intermediate Level (Ages 11-13)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Level up in the data science journey by mastering Python's core concepts, libraries, and methodologies. Learners will harness Python's power for data processing, visualization, and delve into basic machine learning projects, all while emphasizing ethical considerations.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Master Python fundamentals, data structures, and libraries.
              </span>
              <span className="locontent">
                <span>L02: </span>Analyze and visualize datasets using pandas, NumPy, and matplotlib.
              </span>
              <span className="locontent">
                <span>L03: </span>Implement basic machine learning projects.
              </span>
              <span className="locontent">
                <span>L04: </span>Understand ethical aspects of data and machine learning.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Python</li>
                <li>Libraries: NumPy, pandas, matplotlib</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to Python for AI
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Fundamentals of Python
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Data Processing and Visualization
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Practical Applications and Ethics in Data
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Advanced Level (Ages 14-16)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              This advanced module is designed to prepare students for professional data science roles. Deepening their Python knowledge, they'll engage with data modeling, machine learning techniques, and gain insights into big data technologies, ethics, and career opportunities in the field.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Dive deeper into Python programming and its libraries.
              </span>
              <span className="locontent">
                <span>L02: </span>Understand and implement advanced data modeling techniques.
              </span>
              <span className="locontent">
                <span>L03: </span>Undertake hands-on machine learning projects on real-world datasets.
              </span>
              <span className="locontent">
                <span>L04: </span>Grasp the essentials of big data technologies and their applications.
              </span>
              <span className="locontent">
                <span>L05: </span>Engage in deep discussions about data ethics, bias, and societal implications.
              </span>
              <span className="locontent">
                <span>L06: </span>Explore potential career pathways in data science.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Python</li>
                <li>Libraries: scikit-learn, Hadoop, Spark.</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advanced Programming and Libraries
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Deep Dive into Data Modeling
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Real-world Machine Learning Projects
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Big Data, Ethics, and Career Pathways
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : btn2 && !isMobile && !champ && !metaverse? (
        <>
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 1</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Students learn to articulate their thoughts through code and
                  technology
                </li>
                <li>
                  Communicating mathematically to the computer/device via code
                </li>
                <li>Learn to get familiar with basic programming concepts</li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Learners implement and evaluate procedures for
                exploring and solving issues using mathematical knowledge,
                logic, and abilities.
              </span>
              <span className="locontent">
                <span>L02: </span>Getting familiar with basic programming
                concepts like input, output, variables, conditions and loops
              </span>
              <span className="locontent">
                <span>L03: </span>Learn to install and download multiple
                software that helps make games and animations using block
                coding.
              </span>
              <span className="locontent">
                <span>L04: </span>K-turtle interface and fundamental
                instructions are needed to accomplish many transitions.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Computational thinking</li>
                <li>Mathematical knowledge</li>
                <li>Creativity</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 2</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>Bringing ideas from conception to realisation</li>
                <li>Comprehensive skills in the Microsoft Office suite</li>
                <li>
                  Demonstrating a creative mentality (rather than a fixed
                  attitude)
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Utilising fundamental programming components
                in k-turtle regarding degrees and directions to design programs.
              </span>
              <span className="locontent">
                <span>L02: </span>Discover methods to employ MS-Word, MS-Excel
                and MS-PowerPoint.
              </span>
              <span className="locontent">
                <span>L03: </span>Examine advanced alternatives to produce
                animation and games using scratch and programming concepts.
              </span>
              <span className="locontent">
                <span>L04: </span>Simple picture editing using PIXLR.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Picture Editing tools</li>
                <li>Programming Tesselations</li>
                <li>Microsoft Office Suite</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 3</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Understand the programming fundamentals utilizing numerous
                  coding platforms.
                </li>
                <li>
                  Get an advanced degree of knowledge in video and photo
                  editing.
                </li>
                <li>
                  Discuss and produce ideas for the development and
                  implementation of solutions and projects.
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Recording and editing videos using various
                tools to build help design pictures and videos on an advanced
                level.
              </span>
              <span className="locontent">
                <span>L02: </span>Learners will study ideas like algorithms,
                machine learning, and neural networks as a fundamental
                introduction.
              </span>
              <span className="locontent">
                <span>L03: </span>Advanced components of games and apps are
                developed using block coding and programming languages.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Apps and games development</li>
                <li>Managing information and thinking</li>
                <li>Artificial Intelligence</li>
              </ul>
            </Col>
          </Row>
        </>
      ) : ''}
      {btn3 && !isMobile && champ ? (
        <>
        {/* application developement */}
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
                Base Level (Ages 8-10):
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Young learners are introduced to the world of app development through hands-on experiences with Scratch3 and App Lab. They'll design interactive stories, quiz games, and delve into the basics of app creation.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Recognize different types of apps and understand their significance.
              </span>
              <span className="locontent">
                <span>L02: </span>Design interactive projects with Scratch3 incorporating basic user inputs, scoring, and sounds.
              </span>
              <span className="locontent">
                <span>L03: </span>Grasp the basic interface and scripting fundamentals of App Lab.
              </span>
              <span className="locontent">
                <span>L04: </span>Successfully design and present a basic app.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>Scratch3</li>
                <li>App Lab</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to App Development
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Basic App Projects with Scratch3: Animations and Transitions
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Transition to App Lab
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                     Concluding Projects and Presentation
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Intermediate Level (Ages 11-13)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              Building upon the foundation, learners dive deeper into advanced app concepts, interface design, and integrating JavaScript. The focus is on advanced features, scripting, and refining app designs.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Comprehend advanced app logic, event handling, and interface design.
              </span>
              <span className="locontent">
                <span>L02: </span>Learn JavaScript fundamentals and integrate them within App Lab.
              </span>
              <span className="locontent">
                <span>L03: </span>Construct advanced projects in App Lab incorporating game mechanics or tool functionalities.
              </span>
              <span className="locontent">
                <span>L04: </span>Design, refine, and present an app integrated with JavaScript.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>App Lab</li>
                <li>JavaScript</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advancing App Development Concepts
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to JavaScript for Apps
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advanced App Lab Projects
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Concluding Projects with JavaScript and App Lab
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton" style={{ fontSize: "15px" }}>
              Advanced Level (Ages 14-16)
              </Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Course Description:
              </span>
              <span className="locontent" style={{ maxWidth: "100%" }}>
              At the advanced level, learners explore the realm of App Inventor, delving into advanced features, databases, and external APIs. They design complex apps with multiple features, screens, and ensure seamless user experience.
              </span>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Familiarize with App Inventor's interface, design aspects, and coding blocks.
              </span>
              <span className="locontent">
                <span>L02: </span>Design apps integrating advanced features such as databases and location services.
              </span>
              <span className="locontent">
                <span>L03: </span>Construct apps with multiple screens, integrating external APIs.
              </span>
              <span className="locontent">
                <span>L04: </span>Conceive, design, and present a comprehensive app project.
              </span>
              <span className="programhighlightstitle">Tools Used:</span>
              <ul className="phlists">
                <li>App Inventor)</li>
              </ul>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Module No.</th>
                    <th>Module Name</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Introduction to App Inventor
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                     Delving Deeper into App Features
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Advanced App Creation
                    </td>
                    {/* <td colSpan={2}>Larry the Bird</td> */}
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td
                      className="coursedesccontent"
                      style={{ fontSize: "13px" }}
                    >
                      Final App Projects and Presentation
                    </td>
                    <td>4</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : btn3 && !isMobile && !champ ? (
        <>
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 1</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Students learn to articulate their thoughts through code and
                  technology
                </li>
                <li>
                  Communicating mathematically to the computer/device via code
                </li>
                <li>Learn to get familiar with basic programming concepts</li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Learners implement and evaluate procedures for
                exploring and solving issues using mathematical knowledge,
                logic, and abilities.
              </span>
              <span className="locontent">
                <span>L02: </span>Getting familiar with basic programming
                concepts like input, output, variables, conditions and loops
              </span>
              <span className="locontent">
                <span>L03: </span>Learn to install and download multiple
                software that helps make games and animations using block
                coding.
              </span>
              <span className="locontent">
                <span>L04: </span>K-turtle interface and fundamental
                instructions are needed to accomplish many transitions.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Computational thinking</li>
                <li>Mathematical knowledge</li>
                <li>Creativity</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 2</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>Bringing ideas from conception to realisation</li>
                <li>Comprehensive skills in the Microsoft Office suite</li>
                <li>
                  Demonstrating a creative mentality (rather than a fixed
                  attitude)
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Utilising fundamental programming components
                in k-turtle regarding degrees and directions to design programs.
              </span>
              <span className="locontent">
                <span>L02: </span>Discover methods to employ MS-Word, MS-Excel
                and MS-PowerPoint.
              </span>
              <span className="locontent">
                <span>L03: </span>Examine advanced alternatives to produce
                animation and games using scratch and programming concepts.
              </span>
              <span className="locontent">
                <span>L04: </span>Simple picture editing using PIXLR.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Picture Editing tools</li>
                <li>Programming Tesselations</li>
                <li>Microsoft Office Suite</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 3</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Understand the programming fundamentals utilizing numerous
                  coding platforms.
                </li>
                <li>
                  Get an advanced degree of knowledge in video and photo
                  editing.
                </li>
                <li>
                  Discuss and produce ideas for the development and
                  implementation of solutions and projects.
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Recording and editing videos using various
                tools to build help design pictures and videos on an advanced
                level.
              </span>
              <span className="locontent">
                <span>L02: </span>Learners will study ideas like algorithms,
                machine learning, and neural networks as a fundamental
                introduction.
              </span>
              <span className="locontent">
                <span>L03: </span>Advanced components of games and apps are
                developed using block coding and programming languages.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Apps and games development</li>
                <li>Managing information and thinking</li>
                <li>Artificial Intelligence</li>
              </ul>
            </Col>
          </Row>
        </>
      ) : ''}
      {/* {btn1 && !isMobile && (
        <>
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 1</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Students learn to articulate their thoughts through code and
                  technology
                </li>
                <li>
                  Communicating mathematically to the computer/device via code
                </li>
                <li>Learn to get familiar with basic programming concepts</li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Learners implement and evaluate procedures for
                exploring and solving issues using mathematical knowledge,
                logic, and abilities.
              </span>
              <span className="locontent">
                <span>L02: </span>Getting familiar with basic programming
                concepts like input, output, variables, conditions and loops
              </span>
              <span className="locontent">
                <span>L03: </span>Learn to install and download multiple
                software that helps make games and animations using block
                coding.
              </span>
              <span className="locontent">
                <span>L04: </span>K-turtle interface and fundamental
                instructions are needed to accomplish many transitions.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Computational thinking</li>
                <li>Mathematical knowledge</li>
                <li>Creativity</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 2</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>Bringing ideas from conception to realisation</li>
                <li>Comprehensive skills in the Microsoft Office suite</li>
                <li>
                  Demonstrating a creative mentality (rather than a fixed
                  attitude)
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Utilising fundamental programming components
                in k-turtle regarding degrees and directions to design programs.
              </span>
              <span className="locontent">
                <span>L02: </span>Discover methods to employ MS-Word, MS-Excel
                and MS-PowerPoint.
              </span>
              <span className="locontent">
                <span>L03: </span>Examine advanced alternatives to produce
                animation and games using scratch and programming concepts.
              </span>
              <span className="locontent">
                <span>L04: </span>Simple picture editing using PIXLR.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Picture Editing tools</li>
                <li>Programming Tesselations</li>
                <li>Microsoft Office Suite</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 3</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Understand the programming fundamentals utilizing numerous
                  coding platforms.
                </li>
                <li>
                  Get an advanced degree of knowledge in video and photo
                  editing.
                </li>
                <li>
                  Discuss and produce ideas for the development and
                  implementation of solutions and projects.
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Recording and editing videos using various
                tools to build help design pictures and videos on an advanced
                level.
              </span>
              <span className="locontent">
                <span>L02: </span>Learners will study ideas like algorithms,
                machine learning, and neural networks as a fundamental
                introduction.
              </span>
              <span className="locontent">
                <span>L03: </span>Advanced components of games and apps are
                developed using block coding and programming languages.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Apps and games development</li>
                <li>Managing information and thinking</li>
                <li>Artificial Intelligence</li>
              </ul>
            </Col>
          </Row>
        </>
      )} */}
      {/* {btn2 && !isMobile && (
        <>
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 1</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Students learn to articulate their thoughts through code and
                  technology
                </li>
                <li>
                  Communicating mathematically to the computer/device via code
                </li>
                <li>Learn to get familiar with basic programming concepts</li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Learners implement and evaluate procedures for
                exploring and solving issues using mathematical knowledge,
                logic, and abilities.
              </span>
              <span className="locontent">
                <span>L02: </span>Getting familiar with basic programming
                concepts like input, output, variables, conditions and loops
              </span>
              <span className="locontent">
                <span>L03: </span>Learn to install and download multiple
                software that helps make games and animations using block
                coding.
              </span>
              <span className="locontent">
                <span>L04: </span>K-turtle interface and fundamental
                instructions are needed to accomplish many transitions.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Computational thinking</li>
                <li>Mathematical knowledge</li>
                <li>Creativity</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 2</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>Bringing ideas from conception to realisation</li>
                <li>Comprehensive skills in the Microsoft Office suite</li>
                <li>
                  Demonstrating a creative mentality (rather than a fixed
                  attitude)
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Utilising fundamental programming components
                in k-turtle regarding degrees and directions to design programs.
              </span>
              <span className="locontent">
                <span>L02: </span>Discover methods to employ MS-Word, MS-Excel
                and MS-PowerPoint.
              </span>
              <span className="locontent">
                <span>L03: </span>Examine advanced alternatives to produce
                animation and games using scratch and programming concepts.
              </span>
              <span className="locontent">
                <span>L04: </span>Simple picture editing using PIXLR.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Picture Editing tools</li>
                <li>Programming Tesselations</li>
                <li>Microsoft Office Suite</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 3</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Understand the programming fundamentals utilizing numerous
                  coding platforms.
                </li>
                <li>
                  Get an advanced degree of knowledge in video and photo
                  editing.
                </li>
                <li>
                  Discuss and produce ideas for the development and
                  implementation of solutions and projects.
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Recording and editing videos using various
                tools to build help design pictures and videos on an advanced
                level.
              </span>
              <span className="locontent">
                <span>L02: </span>Learners will study ideas like algorithms,
                machine learning, and neural networks as a fundamental
                introduction.
              </span>
              <span className="locontent">
                <span>L03: </span>Advanced components of games and apps are
                developed using block coding and programming languages.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Apps and games development</li>
                <li>Managing information and thinking</li>
                <li>Artificial Intelligence</li>
              </ul>
            </Col>
          </Row>
        </>
      )} */}
      {/* {btn3 && !isMobile && (
        <>
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 1</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Students learn to articulate their thoughts through code and
                  technology
                </li>
                <li>
                  Communicating mathematically to the computer/device via code
                </li>
                <li>Learn to get familiar with basic programming concepts</li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Learners implement and evaluate procedures for
                exploring and solving issues using mathematical knowledge,
                logic, and abilities.
              </span>
              <span className="locontent">
                <span>L02: </span>Getting familiar with basic programming
                concepts like input, output, variables, conditions and loops
              </span>
              <span className="locontent">
                <span>L03: </span>Learn to install and download multiple
                software that helps make games and animations using block
                coding.
              </span>
              <span className="locontent">
                <span>L04: </span>K-turtle interface and fundamental
                instructions are needed to accomplish many transitions.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Computational thinking</li>
                <li>Mathematical knowledge</li>
                <li>Creativity</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 2</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>Bringing ideas from conception to realisation</li>
                <li>Comprehensive skills in the Microsoft Office suite</li>
                <li>
                  Demonstrating a creative mentality (rather than a fixed
                  attitude)
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Utilising fundamental programming components
                in k-turtle regarding degrees and directions to design programs.
              </span>
              <span className="locontent">
                <span>L02: </span>Discover methods to employ MS-Word, MS-Excel
                and MS-PowerPoint.
              </span>
              <span className="locontent">
                <span>L03: </span>Examine advanced alternatives to produce
                animation and games using scratch and programming concepts.
              </span>
              <span className="locontent">
                <span>L04: </span>Simple picture editing using PIXLR.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Picture Editing tools</li>
                <li>Programming Tesselations</li>
                <li>Microsoft Office Suite</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 3</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Understand the programming fundamentals utilizing numerous
                  coding platforms.
                </li>
                <li>
                  Get an advanced degree of knowledge in video and photo
                  editing.
                </li>
                <li>
                  Discuss and produce ideas for the development and
                  implementation of solutions and projects.
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Recording and editing videos using various
                tools to build help design pictures and videos on an advanced
                level.
              </span>
              <span className="locontent">
                <span>L02: </span>Learners will study ideas like algorithms,
                machine learning, and neural networks as a fundamental
                introduction.
              </span>
              <span className="locontent">
                <span>L03: </span>Advanced components of games and apps are
                developed using block coding and programming languages.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Apps and games development</li>
                <li>Managing information and thinking</li>
                <li>Artificial Intelligence</li>
              </ul>
            </Col>
          </Row>
        </>
      )} */}
      {btn4 && !isMobile && (
        <>
          <Row className="innovatorow3">
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 1</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Students learn to articulate their thoughts through code and
                  technology
                </li>
                <li>
                  Communicating mathematically to the computer/device via code
                </li>
                <li>Learn to get familiar with basic programming concepts</li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Learners implement and evaluate procedures for
                exploring and solving issues using mathematical knowledge,
                logic, and abilities.
              </span>
              <span className="locontent">
                <span>L02: </span>Getting familiar with basic programming
                concepts like input, output, variables, conditions and loops
              </span>
              <span className="locontent">
                <span>L03: </span>Learn to install and download multiple
                software that helps make games and animations using block
                coding.
              </span>
              <span className="locontent">
                <span>L04: </span>K-turtle interface and fundamental
                instructions are needed to accomplish many transitions.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Computational thinking</li>
                <li>Mathematical knowledge</li>
                <li>Creativity</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 2</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>Bringing ideas from conception to realisation</li>
                <li>Comprehensive skills in the Microsoft Office suite</li>
                <li>
                  Demonstrating a creative mentality (rather than a fixed
                  attitude)
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Utilising fundamental programming components
                in k-turtle regarding degrees and directions to design programs.
              </span>
              <span className="locontent">
                <span>L02: </span>Discover methods to employ MS-Word, MS-Excel
                and MS-PowerPoint.
              </span>
              <span className="locontent">
                <span>L03: </span>Examine advanced alternatives to produce
                animation and games using scratch and programming concepts.
              </span>
              <span className="locontent">
                <span>L04: </span>Simple picture editing using PIXLR.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Picture Editing tools</li>
                <li>Programming Tesselations</li>
                <li>Microsoft Office Suite</li>
              </ul>
            </Col>
            <Col className="innovatorcols">
              <Button className="levelbutton">Level 3</Button>
              <span
                className="programhighlightstitle"
                style={{ textAlign: "center" }}
              >
                Program highlights
              </span>
              <ul className="phlists">
                <li>
                  Understand the programming fundamentals utilizing numerous
                  coding platforms.
                </li>
                <li>
                  Get an advanced degree of knowledge in video and photo
                  editing.
                </li>
                <li>
                  Discuss and produce ideas for the development and
                  implementation of solutions and projects.
                </li>
              </ul>
              <span className="programhighlightstitle">Learning outcomes</span>
              <span className="locontent">
                <span>L01: </span>Recording and editing videos using various
                tools to build help design pictures and videos on an advanced
                level.
              </span>
              <span className="locontent">
                <span>L02: </span>Learners will study ideas like algorithms,
                machine learning, and neural networks as a fundamental
                introduction.
              </span>
              <span className="locontent">
                <span>L03: </span>Advanced components of games and apps are
                developed using block coding and programming languages.
              </span>
              <span className="programhighlightstitle">Improvement areas</span>
              <ul className="phlists">
                <li>Apps and games development</li>
                <li>Managing information and thinking</li>
                <li>Artificial Intelligence</li>
              </ul>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default Innovator;
