import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import missonimg from "./csimages/Group 815.png";
import vissonimg from "./csimages/Group 818.png";
function MissionVision() {
  return (
    <Container fluid className="missionwrapper">
      <Row className="missionrow">
        <Row className="topcontent">
          <Col className="col1">
            <img src={missonimg} alt="" />
          </Col>
          <Col className="col2">
            <span className="missiontitle">Mission</span>
            <br />
            <span className="mcontent">
              Our company’s mission is to empower young people and equip them
              with skills to help them thrive in an increasingly digital world
              by promoting digital inclusivity and accessibility.
            </span>
          </Col>
        </Row>
        <Row className="topcontent">
          <Col className="col2">
            <span className="missiontitle">Vision</span>
            <br />
            <ul>
                <li>Fostering creativity and innovation in our students</li>
                <li>Enhancing a learning culture</li>
                <li>Developing a science and technology culture with ICT</li>
                <li>Encouraging lifelong learning</li>
                <li>Providing an efficient, effective, and of the highest quality learning environment</li>
            </ul>
          </Col>
          <Col className="vimgcol">
            <img src={vissonimg} alt="" />
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

export default MissionVision;
