import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import image1 from "./csimages/Group 841.png";
import image2 from "./csimages/Group 751.png";
function CRLabs() {
  return (
    <>
      <Container fluid className="crwrapper">
        <Row className="crrow">
          <Col className="crcol1">
            <span className="crheading">
              <span className="crheadsubspan">Coding</span> and{" "}
              <span className="crheadsubspan">Robotic Labs</span>
            </span>
            <br />
            <br />
            <span className="crcontent">
              We also set up Coding and Robotics labs as part of our curriculum.
              Building, operating, and designing robots can lead to acquiring
              high-tech electrical, mechanical, and computer engineering skills
              in high demand in the workplace. The Robotics curriculum we use is
              unique among other curricula because it allows for flexibility and
              creativity in teaching and assessment. We have special programs
              customised only for schools, which include Robotics & Coding Labs,
              Summer Camps, and CS Curriculum.
            </span>
          </Col>
          <Col className="crcol2">
            <img src={image1} alt="" />
          </Col>
        </Row>
      </Container>
      <Container fluid className="reosonwrapper">
        <Row className="reosontitlerow">
          <span>
            Reasons why your <span>child should study at Coding School</span>
          </span>
        </Row>
        <Row className="reosonsrow">
          <Col className="reosons">
            <span>Boosts problem-solving skills</span>
          </Col>
          <Col className="reosons">
            <span>Improves computational thinking</span>
          </Col>
          <Col className="reosons">
            <span>Encourages persistence</span>
          </Col>
          <Col className="reosons">
            <span>Discovers how things work</span>
          </Col>
          <Col className="reosons">
            <span>Helps teach digital literacy</span>
          </Col>
        </Row>
      </Container>
      <Container fluid className="digitalwrapper">
        <Row className="digitalrow">
          <Col className="digitalcol2" style={{maxWidth:'496px'}}>
            <img src={image2} alt="" />
          </Col>
          <Col className="digitalcol1">
            <span className="dheading">
              Coding School
              <span className="dheadsubspan"> is more than <br /> just coding!</span>
            </span>
            <br />
            <br />
            <span className="dcontent">
            Our innovative, activity-based curriculum with live, real-time training promotes numerous life-long skills such as creativity, problem-solving, confidence, persistence, and more that can help kids get ahead in all their future endeavours.
            </span>
            <br />
            <Button className="bookbutton" variant="warning">Book Your Free Demo</Button>
          </Col>
        </Row>
      </Container>
      <Container fluid className="stemwrapper">
        <Row className="stemrow">
          <Row className="stemrowcontent">
            <span className="title" style={{maxWidth:'506px'}}>
              What is <span className="bold">Coding School?</span>
            </span>
            <br />
            <span className="content">
              As a recognition of the quality and efficacy of our Robotics and
              Coding STEM-based programmes, Coding School received accreditation
              from STEM.org – the longest continually operating STEM education
              research and credentialing organization in the United States. The
              accreditation further validates our educational approach to
              teaching students coding and computer science with a unique
              STEM-based curriculum.
            </span>
          </Row>
        </Row>
      </Container>
    </>
  );
}

export default CRLabs;
