import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import creativity from "./opimages/Rectangle 4271.svg";
import ps from "./opimages/Rectangle 4272.svg";
import logic from "./opimages/Rectangle 4273.svg";
import passion from "./opimages/Rectangle 4275.svg";
import persistence from "./opimages/Group 864.svg";
import "./OurPrograms.css";
function Learn() {
  return (
    <Container fluid className="learnwrapper">
      <Row className="learnrow">
        <div>
        <Row className="learntitlerow">
          <span>
            Top skills kids <span>learn by coding</span>
          </span>
        </Row>
        <Row className="learncards">
          <Col className="learncard">
            <div>
              <img src={creativity} alt="" />
              <span className="lcardtitle">Creativity</span>
              <span className="lcardcontent">
                Coding encourages you to showcase your creativity in numerous
                ways
              </span>
            </div>
          </Col>
          <Col className="learncard">
            <div>
              <img src={ps} alt="" />
              <span className="lcardtitle">Problem Solving</span>
              <span className="lcardcontent">
              Computing promotes a problem-solving way of thinking, much as mathematics does.
              </span>
            </div>
          </Col>
          <Col className="learncard">
            <div>
              <img src={logic} alt="" />
              <span className="lcardtitle">Logic</span>
              <span className="lcardcontent">
              Understanding how logic permeates coding provides students with excellent debugging and reasoning skills.
              </span>
            </div>
          </Col>
          <Col className="learncard">
            <div>
              <img src={passion} alt="" />
              <span className="lcardtitle">Passion</span>
              <span className="lcardcontent">
              Coding ignites one's passion and enables kids to pursue their dreams in various directions.
              </span>
            </div>
          </Col>
          <Col className="learncard">
            <div>
              <img src={persistence} alt="" />
              <span className="lcardtitle">Persistence</span>
              <span className="lcardcontent">
              A coding project from scratch teaches students incremental development, problem decomposition, and persistence.
              </span>
            </div>
          </Col>
        </Row>
        </div>
      </Row>
    </Container>
  );
}

export default Learn;
