import React from 'react'
import './Homescreen.css'
import Banner from './Banner';
import LearningPrograms from './LearningPrograms';
import DigitalDivide from './DigitalDivide';
import Provide from './Provide';
import Discover from './Discover';
import McInitiative from './McInitiative';
import SpeakToTeam from './SpeakToTeam';
import { Helmet } from 'react-helmet';
function Homescreen() {
  return (
    <>
    <Helmet>
    <meta
      name="description"
      content="Join the best coding school for kids in UAE to enhance the skills through the online and onsite learning programs like Robotics, Game development, Python Programming and more"
    />
    <meta property="og:title" content="Coding School for Kids | Online Learning Programs UAE | Coding School" />
    <meta property="og:description" content="Join the best coding school for kids in UAE to enhance the skills through the online and onsite learning programs like Robotics, Game development, Python Programming and more" />
    </Helmet>
    <Banner />
    <LearningPrograms />
    <DigitalDivide />
    <McInitiative />
    <Provide />
    <Discover />
    <SpeakToTeam />
    </>
  )
}

export default Homescreen;