import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import logo from "./commonimages/image 3.svg";
import mail from "./commonimages/mail.svg";
import call from "./commonimages/call.svg";
import location from "./commonimages/location.svg";
import robot from "./commonimages/Group 842.svg";
import f from "./commonimages/001-facebook.svg";
import i from "./commonimages/004-instagram.svg";
import l from "./commonimages/linkedin - icon.svg";
import t from "./commonimages/003-twitter.svg";
import westfordadvlogo from './commonimages/westfordventures.svg'
import "./Footer.css";
import { useInView } from "react-intersection-observer";
function Footer() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [animated, setAnimated] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: false, // Trigger animation each time the element comes into view
    threshold: 0.1, // Adjust this threshold as needed
  });

  // Use useEffect to update animated state when inView changes
  React.useEffect(() => {
    if (inView) {
      console.log("inview");
      setAnimated(true);
    }
  }, [inView]);
  const isMobile = screenWidth < 480;

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Container fluid className="footerwrapper">
      <Row className="footermainrow">
        {/* <Row className="logorow">
          <img src={logo} alt="" />
        </Row> */}
        {/* {!isMobile && (
          <> */}
        <Row className="columns">
          <Col className="col1">
            <Row className="logorow">
              <img src={logo} alt="" />
            </Row>
            {/* <ul>
                  <li className="location">
                    Airtics Education, 7th floor Citygate Tower Al Ittihad Road,
                    Al Tawan, Sharjah, UAE.
                  </li>
                </ul>
                <ul>
                  <li className="call">+971 567 887007</li>
                  <li className="mail"> info@codingschool.ai</li>
                </ul>
                <div className="colline" /> */}
          </Col>
          <Col 
          // className="col2"
          ref={ref}
          className={`col2 ${
            animated ? "animate__animated animate__fadeInUp" : ""
          }`}
          >
            <span className="col2head">Company</span>
            <span className="col2content">Home</span>
            <span className="col2content">About Us </span>
            <span className="col2content">Careers</span>
            <span className="col2content">Blog</span>
            <span className="col2content">Programs</span>
            {/* <div className="colline" /> */}
          </Col>
          <Col 
          className={`col3 ${
            animated ? "animate__animated animate__fadeInUp" : ""
          }`}
          >
            <span className="col2head">Our Programs</span>
            <span className="col2content">Web Developer</span>
            <span className="col2content">Game Developer</span>
            <span className="col2content">Python Developer</span>
            <span className="col2content">Code CHAMPS</span>
            {/* <div className="colline" /> */}
          </Col>
          <Col 
          className={`col3 ${
            animated ? "animate__animated animate__fadeInUp" : ""
          }`}
           style={{ border: "none" }} id="last-col3">
            <span className="col2head">Policies</span>
            <span className="col2content">Privacy Policy</span>
            <span className="col2content">Terms & Conditions</span>
            <span className="col2content">Site Map</span>
          </Col>
        </Row>
        {/* </>
        )} */}
        {/* {isMobile && (
          <>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Company</Accordion.Header>
                <Accordion.Body>
                  <span className="col2content">Home</span>
                  <br />
                  <span className="col2content">About Us </span>
                  <br />
                  <span className="col2content">Careers</span>
                  <br />
                  <span className="col2content">Programs</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Programs</Accordion.Header>
                <Accordion.Body>
                  <span className="col2content">Web Developer</span>
                  <br />
                  <span className="col2content">Game Developer</span>
                  <br />
                  <span className="col2content">Python Developer</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Policies</Accordion.Header>
                <Accordion.Body>
                  <span className="col2content">Privacy Policy</span>
                  <br />
                  <span className="col2content">Terms & Conditions</span>
                  <br />
                  <span className="col2content">Site Map</span>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Row className="mobcolumns">
              <Col className="mobcol1">
                <img src={location} alt="" />
                <span>
                  Airtics Education, 7th floor Citygate Tower Al Ittihad Road,
                  Al Tawan, Sharjah, UAE.
                </span>
                <img src={call} alt="" />
                <span>+971 567 887007</span>
                <img src={mail} alt="" />
                <span>info@codingschool.ai</span>
              </Col>
            </Row>
          </>
        )} */}
        <hr />
      </Row>
      <Row className="fdetails">
        <img src={robot} alt="" className="robo"/>
        <Row className="fdrow">

        <Col className="fcol1">
        <div>
          <span>
            <span style={{color:'#faad17'}}>UAE : </span>Coding School, 7th floor Citygate Tower Al Ittihad Road, Al
            Tawan, Sharjah, UAE.
          </span>
          <br />
          <span>
          <span>Email us at info@codingschool.ai or Call +971 6 745 2556 
          </span>
          </span>
          <br />
          <br />
          <span>
          <span style={{color:'#faad17'}}>USA : </span>3790 EL CAMINO REAL UNIT #581 PALO ALTO, CA, United States - 94306
          </span>
          <br />
        </div>
        </Col>
        <Col className="fcol2">
          <img src={westfordadvlogo} alt="" className="westfordlogo"/>
        </Col>
        </Row>

      </Row>
      <Row className="allrights">
        <div className="creditsrow">
          <span>@ 2023 coding school. All Rights Reserved</span>
          <div>
            <img src={f} alt="facebook" />
            <img src={i} alt="instagram" />
            <img src={l} alt="linkidn" />
            <img src={t} alt="twitter" />
            <img src='https://cdn-icons-png.flaticon.com/512/48/48968.png' alt="youtube" style={{maxWidth:'25px'}} />
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default Footer;
