import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import image1 from "./homeimages/Group 592.svg";
import imagemob from "./homeimages/Group 623 (1).svg";
import { useInView } from "react-intersection-observer";
function Discover() {
  const [topicone, setTopicone] = useState(true);
  const [topicTwo, setTopicTwo] = useState(false);
  const [topicThree, setTopicThree] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [animated, setAnimated] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: false, // Trigger animation each time the element comes into view
    threshold: 0.1, // Adjust this threshold as needed
  });

  // Use useEffect to update animated state when inView changes
  React.useEffect(() => {
    if (inView) {
      console.log("inview");
      setAnimated(true);
    }
  }, [inView]);
  const isMobile = screenWidth < 450;

  useEffect(() => {
    // Function to update screen width in state
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add an event listener to update screen width on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const TopicOneHandler = () => {
    setTopicone(true);
    setTopicTwo(false);
    setTopicThree(false);
  };
  const TopicTwoHandler = () => {
    setTopicTwo(true);
    setTopicThree(false);
    setTopicone(false);
  };
  const TopicThreeHandler = () => {
    setTopicThree(true);
    setTopicTwo(false);
    setTopicone(false);
  };
  return (
    <Container className="discoverwrapper" fluid>
      <Row className="discoverrow">
        <span
          ref={ref}
          className={`discovertitle ${
            animated ? "animate__animated animate__zoomIn" : ""
          }`}
        >
          Discover more about the{" "}
          <span className="bold">world of Coding School</span>
        </span>
        <br />
        <span
          className={`whatwedo ${
            animated ? "animate__animated animate__fadeInDown" : ""
          }`}
        >
          What we do:
        </span>
        <br />
        <span
          className={`discovercontent ${
            animated ? "animate__animated animate__fadeInDown" : ""
          }`}
        >
          As a dedicated expert team, we provide personalized 1:1 live classes
          to children, empowering them with the skills of technology creation
          and mathematical thinking. Our innovative approach is supported by our
          proprietary teaching platforms.{" "}
        </span>
      </Row>
      <Row className="discoverrow2">
        <img
          src={isMobile ? imagemob : image1}
          alt=""
          style={{ margin: isMobile ? "5rem 0 0 6px" : "" }}
        />
        <Col className="cardrows">
          <Row
            // className="cardrows2"
            className={`cardrows2 ${
              animated
                ? "animate__animated animate__fadeInDown cstdelay200"
                : ""
            }`}
            onClick={TopicOneHandler}
            style={{
              background: topicone
                ? "linear-gradient(288.6deg, #1e1152 -5.19%, #47368b 79.29%)"
                : "#ffffff",
              color: topicone ? "#ffffff" : "",
              boxShadow: topicone
                ? "11px 9px 20px rgba(121, 106, 184, 0.4)"
                : "0px 4px 4px rgba(213, 209, 224, 0.47)",
            }}
          >
            Topics covered in the Coding School curriculum:
          </Row>
          <Row
            className={`cardrows2 ${
              animated
                ? "animate__animated animate__fadeInDown cstdelay500"
                : ""
            }`}
            onClick={TopicTwoHandler}
            style={{
              background: topicTwo
                ? "linear-gradient(288.6deg, #1e1152 -5.19%, #47368b 79.29%)"
                : "#ffffff",
              color: topicTwo ? "#ffffff" : "",
              boxShadow: topicTwo
                ? "11px 9px 20px rgba(121, 106, 184, 0.4)"
                : "0px 4px 4px rgba(213, 209, 224, 0.47)",
            }}
          >
            How our curriculum enhances academic performance in kids:
          </Row>
          <Row
            className={`cardrows2 ${
              animated
                ? "animate__animated animate__fadeInDown cstdelay800"
                : ""
            }`}
            onClick={TopicThreeHandler}
            style={{
              background: topicThree
                ? "linear-gradient(288.6deg, #1e1152 -5.19%, #47368b 79.29%)"
                : "#ffffff",
              color: topicThree ? "#ffffff" : "",
              boxShadow: topicThree
                ? "11px 9px 20px rgba(121, 106, 184, 0.4)"
                : "0px 4px 4px rgba(213, 209, 224, 0.47)",
            }}
          >
            How we select kids for our programs:
          </Row>
        </Col>
        <Col className="cardrowss">
          {topicone && (
            <>
              <span>
                Our comprehensive curriculum at Coding School includes a diverse
                range of fun-based and interactive subjects. Along with coding,
                robotics, game development, and metaverse classes, we also offer
                workshops, exhibitions, and project assistance to kids.
                Additionally, we extend our support to schools in setting up
                robotic labs on their premises.
              </span>
            </>
          )}
          {topicTwo && (
            <>
              <span>
                The curriculum at Coding School is specifically designed to
                complement the school curriculum and contribute to better
                academic performance. Through our coding, robotics, and game
                development programs, kids develop essential skills such as
                problem-solving, creativity, and critical thinking. These skills
                not only benefit their technical knowledge but also positively
                impact their regular studies. Our curriculum also focuses on
                building confidence in children, empowering them to take on
                challenges and pursue their interests with enthusiasm.
              </span>
            </>
          )}
          {topicThree && (
            <>
              <span>
                At Coding School, we carefully consider the developmental needs,
                interests, strengths, and challenges of each child to determine
                the best-suited program for them. We offer a range of programs,
                including Robo Whiz for robotics, Champ Coder for coding, Gaming
                Honcho for game development, and Metaverse Maestro for studying
                the basics of Metaverse and blockchain. Our team of experts
                provides personalised attention to each child, regularly
                updating parents on their progress. We are dedicated to helping
                children discover their passion and unlock their full potential.
              </span>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Discover;
