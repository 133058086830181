import React, { useState } from "react";
// import { styled } from "frontity";
import { Container, Row } from "react-bootstrap";
import v from "./robowhizimages/Left.svg";
import img1 from "./robowhizimages/Group 1 (32).svg";
import { styled } from "styled-components";
function Questions({ data }) {
  const [open, setOpen] = useState({});

  const onToggle = (id) => {
    setOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  return (
    <Wrapper fluid>
      <Roww1>
        <img src={img1} alt="" />
        <SPAN1>Frequently Asked Questions</SPAN1>
      </Roww1>
      <Row1>
        <Row2>
          <Details
            style={{ marginTop: "15px", top: "0" }}
            onClick={() => onToggle(1)}
          >
            <Summary>
              <span>
                Why start at a young age? How can my kid grasp all the knowledge
                from a nascent age?
              </span>
              <Div rotated={open[1]}>
                <img src={v} alt="" />
              </Div>
            </Summary>
            <P>
              The ability of our brain to develop new neuron connections and
              learn new things is the greatest before the age of seven years.
              Starting young helps children develop their mindset in a way that
              will help them in the long term. Children will be able to nurture
              key skills like decision-making, logical thinking, creative
              problem solving and group coordination. Early exposure to our
              curated curriculum, along with certified early childhood teaching
              experts, will help kids in developing Academic and Emotional
              Intelligence.
            </P>
          </Details>
          <hr />
          <Details onClick={() => onToggle(2)}>
            <Summary>
              <span>How RoboCHAMPS different from others?</span>

              <Div rotated={open[2]}>
                <img src={v} alt="" />
              </Div>
            </Summary>
            <P>
              The ability of our brain to develop new neuron connections and
              learn new things is the greatest before the age of seven years.
              Starting young helps children develop their mindset in a way that
              will help them in the long term. Children will be able to nurture
              key skills like decision-making, logical thinking, creative
              problem solving and group coordination. Early exposure to our
              curated curriculum, along with certified early childhood teaching
              experts, will help kids in developing Academic and Emotional
              Intelligence.
            </P>
          </Details>
          <hr />
          <Details onClick={() => onToggle(3)}>
            <Summary>
              <span>
                Will it help my child in improving their performance in
                academics?
              </span>
              <Div rotated={open[3]}>
                <img src={v} alt="" />
              </Div>
            </Summary>
            <P>
              The ability of our brain to develop new neuron connections and
              learn new things is the greatest before the age of seven years.
              Starting young helps children develop their mindset in a way that
              will help them in the long term. Children will be able to nurture
              key skills like decision-making, logical thinking, creative
              problem solving and group coordination. Early exposure to our
              curated curriculum, along with certified early childhood teaching
              experts, will help kids in developing Academic and Emotional
              Intelligence.
            </P>
          </Details>
          <hr />
        </Row2>
      </Row1>
    </Wrapper>
  );
}

export default Questions;
// css
// wrapper
const Wrapper = styled(Container)`
  background: #fff;
  padding-top: 4rem;
  padding-bottom: 3rem;
  @media (max-width: 480px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
const Row1 = styled(Row)`
  padding-bottom: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 480px) {
    width: 100%;
    padding-bottom: 0;
  }
`;
const Roww1 = styled(Row)`
  margin: 0 auto;
  max-width: 1200px;
  height: 106.27px;
  display: flex;
  align-items: center;
  position: relative;
  img {
    width: 100px;
    height: 100px;
    position: absolute;
    top: -105%;
    right: 0;
  }
  @media (max-width: 480px) {
    height: auto;
    img {
      display: none;
    }
  }
`;
const Row2 = styled(Row)`
  max-width: 1200px;

  margin-top: 3rem;
  margin-bottom: 3rem;
  /* min-height: 1097.35px; */
  margin: 0 auto;
`;
const Div = styled.div`
  max-height: 25px;
  transform: ${(props) => (props.rotated ? "rotate(-180deg)" : "")};

  @media (max-width: 480px) {
    img {
      max-width: 17px;
    }
  }
`;
const SPAN1 = styled.span`
  color: #303030;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 122.222% */
  text-align: center;
  margin: 2rem 0;
  text-transform: capitalize;

  color: #303030;
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 44px;
    margin: 2rem 0 0;
  }
`;

const Details = styled.details`
  padding-left: 20px;
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 400;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 25px;
  z-index: 1;
  /* or 171% */
  transition: transform 5.3s ease-in-out;
  animation: slowmation 18s infinite;
  details summary::-webkit-details-marker {
    display: none;
  }
  //   transform: ${(props) =>props.open ? "rotate(-45deg)" : "rotate(135deg)"}
  //   transition: transform 0.3s ease-in-out;
  @media (max-width: 650px) {
    /* margin-top: 25px;

    position: relative;
    top: -26px; */
  }
  @media (max-width: 480px) {
    padding-left: 0;
  }

  details[open] > summary::after {
    transform: rotate(-40deg);
  }
  summary {
    list-style: none;
    display: flex;
    z-index: 1;
    justify-content: space-between;
    @media (max-width: 650px) {
      display: flex;
      gap: 10px;
    }
  }

  color: #303030;
`;
const Summary = styled.summary`
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  display: flex;
  justify-content: space-between;
  /* text-transform: capitalize; */
  padding-top: 10px;

  color: #303030;
  @media (max-width: 480px) {
    font-size: 12px;
    line-height: 24px;
  }
`;
const P = styled.p`
  margin-top: 20px;
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  @media (max-width: 480px) {
    font-size: 11px;
    line-height: 22px;
  }
`;
