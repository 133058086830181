import './App.css';
import { BrowserRouter , Routes ,Route} from 'react-router-dom';
import Homescreen from './Homescreen/Homescreen';
import NavBar from './commoncomponents/Navbar';
import Footer from './commoncomponents/Footer';
import Robowhiz from './Robowhiz/Robowhiz';
import Codingschool from './whycodingschool/Codingschool';
import OurPrograms from './Ourprograms/OurPrograms';
import ContactUs from './contactus/ContactUs';

function App() {
  return (
    <>
    <BrowserRouter>
    <NavBar />
    <Routes>
      <Route path='/' element={<Homescreen/>}/>
      <Route path='/robotics-for-kids' element={<Robowhiz/>}/>
      <Route path='/why-coding-school' element={<Codingschool/>}/>
      <Route path='/our-programs' element={<OurPrograms/>}/>
      <Route path='/champ-coder' element={<OurPrograms champ='true'/>}/>
      <Route path='/game-development-for-kids' element={<OurPrograms gaming='true'/>}/>
      <Route path='/artificial-intelligence-programs-for-kids' element={<OurPrograms metaverse='true'/>}/>
      <Route path='/contact-us' element={<ContactUs/>}/>
    </Routes>
    <Footer />
    </BrowserRouter>
    </>
  );
}

export default App;
