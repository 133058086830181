import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";

function McInitiative() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [animated, setAnimated] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: false, // Trigger animation each time the element comes into view
    threshold: 0.1, // Adjust this threshold as needed
  });

  // Use useEffect to update animated state when inView changes
  React.useEffect(() => {
    if (inView) {
      console.log("inview");
      setAnimated(true);
    }
  }, [inView]);
  const isMobile = screenWidth < 480;

  useEffect(() => {
    // Function to update screen width in state
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add an event listener to update screen width on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Container className="mcwrapper" fluid>
      <Row className="mcrow">
        <div>
          <span
           ref={ref}
           className={`mcheading ${
             animated ? "animate__animated animate__zoomIn" : ""
           }`}
          >
          Why{" "}
            <span className="mcbold"> ‘Million Coders’ ? </span>
          </span>
          <Row>
            <ul className="mccontents">
            {isMobile ? (
        <>
          <li>
          Instigate an interest in programming.
              </li>
              <li>
              Participation fee as minimum as $10 per individual.
              </li>
              <li>
              A diverse range of tools & technologies to explore
              </li>
              <li>
              Customizable learning paths catering to your interests.
              </li>
              <li>
              Recorded sessions allow participants to learn at their own pace.
              </li>
              <li>
              Multiple exit pathways to ensure practical application and future readiness.
              </li>
              <li>
              A community that supports, nurtures, and grows together.
              </li>
              <li>
              Annual coding championship/hackathon where students and professionals from 25+ countries participate.
              </li>
              <li>
              The winning groups receive a reward from a $20,000 prize pool.
              </li>
              <li>Outstanding participants will receive Airtics scholarships for pursuing advanced programs. </li>
              <li>Acacia University, USA, awards a university certification to all participants who complete the learning journey.</li>
              <li>Being a part of this initiative, one can enhance their credentials both academically and professionally. </li>
              <li>Inclusive education ensuring everyone gets to be a part of the digital future.</li>
        </>
      ) : (
        <>
          <li>
          Instigate an interest in programming.
              </li>
              <li>
              Participation fee as minimum as $10 per individual.
              </li>
              <li>
              A diverse range of tools & technologies to explore
              </li>
              <li>
              Customizable learning paths catering to your interests.
              </li>
              <li>
              Recorded sessions allow participants to learn at their own pace.
              </li>
              <li>
              Multiple exit pathways to ensure practical application and future readiness.
              </li>
              <li>
              A community that supports, nurtures, and grows together.
              </li>
              <li>
              Annual coding championship/hackathon where students and professionals from 25+ countries participate.
              </li>
              <li>
              The winning groups receive a reward from a $20,000 prize pool.
              </li>
              <li>Outstanding participants will receive Airtics scholarships for pursuing advanced programs. </li>
              <li>Acacia University, USA, awards a university certification to all participants who complete the learning journey.</li>
              <li>Being a part of this initiative, one can enhance their credentials both academically and professionally. </li>
              <li>Inclusive education ensuring everyone gets to be a part of the digital future.</li>
        </>
      )}
              
            </ul>
          </Row>
        </div>
      </Row>
    </Container>
  );
}

export default McInitiative;
