import React, { useState } from "react";
import { Button, Row } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
// import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
function ContactForm({ actions, ht, popup }) {
  const [formData, setFormData] = useState({
    fullName: "",
    country: "",
    email: "",
    ContactNo: "",
  });
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleContactChange = (value) => {
    setFormData({
      ...formData,
      ContactNo: value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // Extract form data here and format it as needed
    // const formData = new FormData(event.target);
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });
    // Send the data to the Google Apps Script
    const response = await fetch(
        "https://script.google.com/macros/s/AKfycbyY1Dew_WC2abeCvHR_jAl1wVktnfO6e4WPbPRQfEhWNPk4OXSbCdJC7x1DvKqH1K0K9g/exec",
      {
        method: "POST",
        body: formDataToSend,
      }
    );

    // Handle the response if needed
    if (response.ok) {
      // Successful submission
      alert("Form submitted successfully");
      setFormData({ // Clear the form fields
        fullName: "",
        country: "",
        email: "",
        // mobileNumber: "",
      });
    } else {
      // Handle errors
      console.error("Form submission failed");
    }
  };
  return (
    <>
      <Roww2 ht={ht ? "true" : "false"} popup={popup}>
        <Row3>
        <form onSubmit={handleSubmit}>
            <Row className="form">
              {/* <Col className="formcol"> */}
              <FormGroup>
              <FormField
                type="text"
                name="fullName"
                placeholder="Full Name"
                value={formData.fullName}
                onChange={handleChange}
                required
                style={{width:'100%',paddingLeft:'0'}}
              />
              <FormLabel htmlFor="name" className="form__label">
              Full Name
              </FormLabel>
            </FormGroup>
            <FormGroup>
              <FormField
                type="text"
                name="country"
                placeholder="Country"
                value={formData.country}
                onChange={handleChange}
                required
                style={{width:'100%',paddingLeft:'0'}}

              />
              <FormLabel htmlFor="name" className="form__label">
              Country
              </FormLabel>
            </FormGroup>
            <FormGroup>
              <FormField
                type="text"
                name="email"
                placeholder="E-Mail"
                value={formData.email}
                onChange={handleChange}
                required
                style={{width:'100%',paddingLeft:'0'}}

              />
              <FormLabel htmlFor="name" className="form__label">
              E-Mail
              </FormLabel>
            </FormGroup>
            <FormGroup>
                  <PhoneInput
                    placeholder="Phone number"
                    id="contact"
                    name="ContactNo"
                    defaultCountry="AE"
                    value={formData.ContactNo}
                    onChange={handleContactChange}
                  />
                </FormGroup>
                
            </Row>
            <Row>
              <Submit type="submit" variant="">
                Submit
              </Submit>
            </Row>
          </form>

        </Row3>
      </Roww2>
    </>
  );
}

export default ContactForm;

const Roww2 = styled(Row)`
  width: 285px;
  /* min-height: 636.8px; */
  padding-bottom: 20px;
  background: #ffffff;
  /* box-shadow: 0px 13px 45px rgba(215, 215, 215, 0.62); */
  border-radius: 20px;
  position: ${(props) =>
    props.ht === "true" ? "" : props.popup ? "" : "absolute"};

  @media (max-width: 1350px) {
    max-width: 700px;
    position: relative;
    margin: 2rem auto 0;
  }
  @media (max-width: 650px) {
    margin: 0 auto;
  }
`;
const Row3 = styled(Row)`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0 10px;
  // max-width:349px;
`;
// input styles
const FormGroup = styled.div`
  position: relative;
  padding: 15px 0 0;
  margin: 10px auto 0;
  width: 342px;
  height: 54px;
  /* max-height: 2rem; */
  @media (max-width: 410px) {
    width: 95%;
  }
`;

const FormField = styled.input`
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 1px solid #b7b7b7;
  &:focus {
    border-bottom: 1px solid #fcaf17;
  }
  @media (max-width: 410px) {
    width: 95%;
  }
  outline: 0;
  font-size: 16px;
  /* color: #fff; */
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 16px;
    cursor: text;
    top: 20px;
  }

  &:focus {
    ~ .form__label {
      position: absolute;
      top: 0;
      display: block;
      transition: 0.2s;
      font-size: 18px;
      color: black;
      font-weight: 400;
    }
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-bottom: 1px solid #fcaf17;
    /* border-image: linear-gradient(to right, #11998e, #38ef7d); */
    border-image-slice: 1;
  }
`;

const FormLabel = styled.label`
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b;
`;
const Submit = styled(Button)`
background: #302363;
    border-radius: 5px;
    max-width: 182px;
    height: 48px;
    margin: 0 auto;
    border: none;
    margin-top: 25px;
    color: #ffffff;
    &:hover{
background: #302363;
color: #ffffff;
    }
`;