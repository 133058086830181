import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import ind from "./robowhizimages/indian-flag-64 1.svg";
import bag from "./robowhizimages/icons8-suitcase-100 1.svg";
import training from "./robowhizimages/icons8-teacher-100 1.svg";
import cardbanner from "./robowhizimages/Group 1 (9).png";
import bckimg from "./robowhizimages/Group 1 (11).png";
import mobbckimg from "./robowhizimages/Group 717.svg";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";
function Global() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobile = screenWidth < 480;

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    // arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <LeftArrow global/>,
    nextArrow: <RightArrow global/>,
    // nextArrow: <RightArw />,
  };
  return (
    <Container fluid className="globalwrapper">
      <img src={isMobile ?  mobbckimg : bckimg} alt="" className="backgroundimg"/>
      <Row className="globaltitlerow">
        <span>
          Our team of Global
          <br />
          <span>Experts and Educators</span>
        </span>
      </Row>
      {/* slider */}
      <Row className="gsliderrow">
        <Slider {...settings} className="globalslider">
          <Col className="globalcard">
            <img src={cardbanner} alt="globalcardimage" className="globalcardimage"/>
            <Row className="carddetails">
              <div className="rating">
                <span>5.0 <br /><span>
                Rating</span></span>
              </div>
              <div>
                <span className="cardname">John Doe</span>
                <img src={ind} alt="" />
              </div>
              <div>
                <img src={bag} alt="" />
                <span className="cardexp">3+ Year Experience</span>
              </div>
              <div>
                <img src={training} alt="" />
                <span className="cardexp">
                  Trainer of python, Arduino, Pictoblox, IoT
                </span>
              </div>
              <ul>
                <li>Lorem ipsum dolor</li>
                <li>consectetur adipiscing elit</li>
                <li>sed do eiusmod tempor</li>
              </ul>
            </Row>
          </Col>
          <Col className="globalcard">
            <img src={cardbanner} alt="globalcardimage" className="globalcardimage"/>
            <Row className="carddetails">
            <div className="rating">
                <span>5.0 <br /><span>
                Rating</span></span>
              </div>
              <div>
                <span className="cardname">John Doe</span>
                <img src={ind} alt="" />
              </div>
              <div style={{ marginBottom: "10px" }}>
                <img src={bag} alt="" />
                <span className="cardexp">3+ Year Experience</span>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <img src={training} alt="" />
                <span className="cardexp">
                  Trainer of python, Arduino, Pictoblox, IoT
                </span>
              </div>
              <ul>
                <li>Lorem ipsum dolor</li>
                <li>consectetur adipiscing elit</li>
                <li>sed do eiusmod tempor</li>
              </ul>
            </Row>
          </Col>
          <Col className="globalcard">
            <img src={cardbanner} alt="globalcardimage" className="globalcardimage"/>
            <Row className="carddetails">
            <div className="rating">
                <span>5.0 <br /><span>
                Rating</span></span>
              </div>
              <div>
                <span className="cardname">John Doe</span>
                <img src={ind} alt="" />
              </div>
              <div>
                <img src={bag} alt="" />
                <span className="cardexp">3+ Year Experience</span>
              </div>
              <div>
                <img src={training} alt="" />
                <span className="cardexp">
                  Trainer of python, Arduino, Pictoblox, IoT
                </span>
              </div>
              <ul>
                <li>Lorem ipsum dolor</li>
                <li>consectetur adipiscing elit</li>
                <li>sed do eiusmod tempor</li>
              </ul>
            </Row>
          </Col>
          <Col className="globalcard">
            <img src={cardbanner} alt="globalcardimage" className="globalcardimage"/>
            <Row className="carddetails">
            <div className="rating">
                <span>5.0 <br /><span>
                Rating</span></span>
              </div>
              <div style={{ marginBottom: "15px" }}>
                <span className="cardname">John Doe</span>
                <img src={ind} alt="" />
              </div>
              <div>
                <img src={bag} alt="" />
                <span className="cardexp">3+ Year Experience</span>
              </div>
              <div>
                <img src={training} alt="" />
                <span className="cardexp">
                  Trainer of python, Arduino, Pictoblox, IoT
                </span>
              </div>
              <ul>
                <li>Lorem ipsum dolor</li>
                <li>consectetur adipiscing elit</li>
                <li>sed do eiusmod tempor</li>
              </ul>
            </Row>
          </Col>
          <Col className="globalcard">
            <img src={cardbanner} alt="globalcardimage" className="globalcardimage"/>
            <Row className="carddetails">
            <div className="rating">
                <span>5.0 <br /><span>
                Rating</span></span>
              </div>
              <div>
                <span className="cardname">John Doe</span>
                <img src={ind} alt="" />
              </div>
              <div>
                <img src={bag} alt="" />
                <span className="cardexp">3+ Year Experience</span>
              </div>
              <div>
                <img src={training} alt="" />
                <span className="cardexp">
                  Trainer of python, Arduino, Pictoblox, IoT
                </span>
              </div>
              <ul>
                <li>Lorem ipsum dolor</li>
                <li>consectetur adipiscing elit</li>
                <li>sed do eiusmod tempor</li>
              </ul>
            </Row>
          </Col>
        </Slider>
      </Row>
    </Container>
  );
}

export default Global;
