import React from 'react'
import Banner from '../contactus/Banner'
import CsAbout from './CsAbout'
import LearningPrograms from '../Homescreen/LearningPrograms'
import MissionVision from './MissionVision'
import CRLabs from './CRLabs'
import Whycs from './Whycs'
import Thrive from './Thrive'
import Curriculum from './Curriculum'
import SpeakToTeam from '../Homescreen/SpeakToTeam'
function Codingschool() {
  return (
    <>
    <Banner cs='true'/>
    <CsAbout />
    <LearningPrograms Robowhiz='true'/>
    <MissionVision />
    <CRLabs />
    <Whycs />
    <Thrive />
    <Curriculum />
    <SpeakToTeam />
    </>
  )
}

export default Codingschool