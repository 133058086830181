import React, { useEffect, useState } from "react";
import arrow from "./robowhizimages/Group 1 (28).svg";
import { styled } from "styled-components";

function LeftArrow({ onClick, testimonials }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobile = screenWidth < 870;
  const isMobile480 = screenWidth < 480;

  useEffect(() => {
    // Function to update screen width in state
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    // Add an event listener to update screen width on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Div
      style={{
        left:testimonials && !isMobile ? '34px' : isMobile && testimonials ? '34%' : isMobile480 && global ? '40%' : '',
        bottom: testimonials ? "-36px" : "",
      }}
      testimonials={testimonials}
    >
      <Div1 onClick={onClick}>
        <Image src={arrow} alt="" />
      </Div1>
    </Div>
  );
}

export default LeftArrow;

const Div1 = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  opacity: 1;
`;
const Div = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  bottom: -21%;
  right: 11%;
  z-index: 1;
  opacity: 1;
  @media (max-width: 1200px) {
    right: 15%;
    /* top: 104%; */
  }
  @media (max-width: 775px) {
    right: 20%;
  }
  @media (max-width: 480px) {
    right: 22%;
    /* right:8%; */
    /* top: 104%; */
top:${(props) => (props.testimonials ? "95%" : "104%")};

  }
  @media (max-width: 375px) {
    right: 25%;
    top:${(props) => (props.testimonials ? "" : "104%")};

  }
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
